import * as axios from "../network/Network";

const config = require("../network/api.json");

class BasicUsersService 
{
  static BasicUsers(search,status,pno) {

    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.BasicUsers+`?search=${search}&status=${status}&page=${pno}`
    });
    console.log(result.url);
    return Promise.resolve(result);
  }



}
export {BasicUsersService };