import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./store";
import Dashboard from "./components/pages/StylishUsers/StylishUsers";
import { alertActions, userLogout } from "./actions";
import Login from "./components/pages/Login/LoginScreen";
import "bootstrap/dist/css/bootstrap.min.css";
import StylishUsers from "./components/pages/StylishUsers/StylishUsers";
import ViewUsers from "./components/pages/ViewUsers/ViewUsers";
import BasicUsers from "./components/pages/BasicUsers/BasicUsers";
import RequestBidPoints from "./components/pages/RequestBidPoints/RequestBidPoints";
import ListWithdrawal from "./components/pages/ListWithdrawals/ListWithdrawal"
import ViewWithdrawals from "./components/pages/ViewWithdrawals/ViewWithdrawals";
import FavoriteFashionList from "./components/pages/FavoriteFashionList/FavoriteFashionList";
import FavoriteFashionView from "./components/pages/FavoriteFashionView/FavoriteFashionView";
import FavoriteBrandList from "./components/pages/FavoriteBrandList/FavoriteBrandList";
import FavoriteBrandView from "./components/pages/FavoriteBrandView/FavoriteBrandView";
import UserManual from "./components/pages/UserManual/UserManual";
import News from "./components/pages/News/News";
import ViewNewsUsers from "./components/pages/ViewNewsUsers/ViewNewsUsers";
import FAQHeader from "./components/pages/FAQheader/FAQheader";
import FAQs from "./components/pages/FAQs/FAQs";
import FAQsView from "./components/pages/FAQsView/FAQsView";
import StylistBlog from "./components/pages/StylistBlog/StylistBlog";
import ViewBasicUsers from "./components/pages/ViewBasicUsers/ViewBasicUsers";
import BlogView from "./components/pages/BlogView/BlogView";
import Links from "./components/pages/Links/links";
  const Main = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const alert = useSelector((state) => state?.alert);
  const user = JSON.parse(localStorage.getItem("state"));
  const isUser = user ? Object.keys(user.userData).length : 0;
  const session_expired = user?.userData?.session_expired;


  useEffect(() => {
    setTimeout(() => dispatch(alertActions.clear()), 3000);
    // eslint-disable-next-line
  }, [alert.message]);


  //session expire
  useEffect(() => {
    if (session_expired) {
      dispatch(alertActions.error("Authentication error..Please login"));
      setTimeout(() => {
        dispatch(userLogout());
        navigate("/");
      }, 100);
    }
    // eslint-disable-next-line
  }, [session_expired]);

  return (
    <div>
      {alert.message && (
        <div className={`alert ${alert.type}`}>{alert.message}</div>
      )}
      <Routes>
        {/* <Route path="/faqheader-view/:faqheader_id" element={<FAQHeaderView/>}/> */}
      <Route path="/dashboard" element={<Dashboard/>}/>
      <Route path="/stylistblog" element={ <StylistBlog />}/>
      <Route path="/stylish-users" element={<StylishUsers/>} />
      <Route path="/view-users/:viewer_id"  element={<ViewUsers/>}/>
      <Route path="/view-basicusers/:viewer_id" element={<ViewBasicUsers/>}/>
      <Route path="/withdrawal-list" element={<ListWithdrawal/>}/>
      <Route path="/withdrawal-view/:viewer_id" element={<ViewWithdrawals/>}/>
      <Route path="/basic-users" element={<BasicUsers/>} />
      <Route path="/request-bid-points" element={<RequestBidPoints/>} />
      <Route path="/favorite-fashion" element={<FavoriteFashionList/>} />
      <Route path="/favorite-fashion-view/:favorite_id" element={<FavoriteFashionView />} />
      <Route path="/favorite-brand" element={<FavoriteBrandList/>}/>
      <Route path="/favorite-brandview/:viewer_id" element={<FavoriteBrandView/>}/>
      <Route path="/usermanual" element={<UserManual/>}/>
      <Route path="/news" element={<News/>} />
      <Route path="/faq-header" element={<FAQHeader/>} />
      <Route path="/faq" element={<FAQs/>} />
      <Route path="/view-faq/:faq_id" element={<FAQsView/>} />
      <Route path="/view-news/:news_id" element={<ViewNewsUsers/>} />
     
      <Route path="/blog-view/:blog_id" element={<BlogView />} />
      <Route path="/link-list" element={<Links/>} />
      <Route path="/" 
       element={isUser ? <Navigate to="/stylish-users" /> : <Login />} />
      <Route
          path="/login"
          element={isUser ? <Navigate to="/" /> : <Login />}
        />

      </Routes>

      
    </div>
  );
};

export default function App() {
  return (
    <Provider store={store}>
      <Main />
    </Provider>
  );
}
