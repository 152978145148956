import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../actions";
import { NewsService } from "../../../services/NewsService";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-image-crop/dist/ReactCrop.css";


const useNewsHooks = (props) => {
    const { t } = props
    const dispatch = useDispatch();
    const [data, setData] = useState("")
    const [search, setSearch] = useState("")
    const [current, setCurrent] = useState(1)
    const [title, setTitle] = useState("")
    const [dsc, setDescription] = useState("")
    const [status, setStatus] = useState('')
    const [limitFile, setLimitFile] = useState(true); // file size limit 10 Mb
    const [validFile, setValidFile] = useState(true); // file type must be image only
    const [fileName, setFilename] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState();
    const [isOpened, setIsOpened] = useState(false);
    const [storeId, setStoreId] = useState("");
    const [show, setShow] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [loader, setLoader] = useState(false);
    const [srcImg, setSrcImg] = useState(null);
    const [image, setImage] = useState(null);
    const [crop, setCrop] = useState({ aspect: 16 / 9 });
    const [result, setResult] = useState(null);
    const [displayCrop, setDisplayCrop] = useState(null);
    const [editCrop, setEditCrop] = useState(false);
    const ref = React.useRef();
    const media_url = process.env.REACT_APP_MEDIA_DOMAIN;


    const searchHandler = useCallback(
        (e) => {
            setSearch(e.target.value)
            setCurrent("");
        },
        [search, setSearch],
    )

    const changeCurrentPage = (page) => {
        setCurrent(page)
    }

    const changeHandler = useCallback(
        (e) => {
            if (e.target.name === 'title') {
                setTitle(e.target.value)
            }
            if (e.target.name === 'dsc') {
                setDescription(e.target.value)
            }
            if (e.target.name === 'status') {
                setStatus(e.target.value)
            }
        }, [title, setTitle, dsc, setDescription, status, setStatus]
    )

    const changeImageHandler = useCallback(
        (e) => {
          if (e.target.files.length) {
            setImagePreviewUrl(null);
            if (e.target.files[0].size > 1000000 * 10) {
              setLimitFile(false);
              e.target.files = null;
              return false;
            }
            else {
              setSelectedFile(e.target.files[0]);
              e.preventDefault();
              let reader = new FileReader();
              let file = e.target.files[0];
              console.log(file);
              if (file.type.match('image/png') || file.type.match('image/jpg') || file.type.match('image/jpeg') || file.type.match('image/svg')) {
                reader.onloadend = () => {
                  setImagePreviewUrl(reader.result);
                  setValidFile(true)
                }
              }
              else {
                setValidFile(false)
              }
              reader.readAsDataURL(file);
            }
          }
        },[imagePreviewUrl, setImagePreviewUrl,selectedFile, setSelectedFile]
      )
    
    useEffect(() => {
        setTimeout(() => {
        }, 1000);
        newsDisplay();
    }, [search, current])

    const newsDisplay = () => {
        NewsService.News(search, current)
            .then((data) => {
                setData(data.data)
                setLoader(true)
            })
            .catch((error) => {
                console.log("Error", error)
                setLoader(false)
            })
    }

    /* image cropping start */
    // const getCroppedImg = async () => {
    //     try {
    //         const canvas = document.createElement("canvas");
    //         const scaleX = image.naturalWidth / image.width;
    //         const scaleY = image.naturalHeight / image.height;
    //         canvas.width = crop.width;
    //         canvas.height = crop.height;
    //         const ctx = canvas.getContext("2d");
    //         ctx.drawImage(
    //             image,
    //             crop.x * scaleX,
    //             crop.y * scaleY,
    //             crop.width * scaleX,
    //             crop.height * scaleY,
    //             0,
    //             0,
    //             crop.width,
    //             crop.height
    //         );
    //         // to display crop
    //         const base64Image = canvas.toDataURL("image/png", 1);
    //         setDisplayCrop(base64Image);
    //         //to api:
    //         var file = dataURLtoFile(base64Image, 'a.png');
    //         console.log(file);
    //         setResult(file);
    //     } catch (e) {
    //         console.log("crop the image");
    //     }
    // };
    // to binary
    // function dataURLtoFile(dataurl, filename) {
    //     var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    //         bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    //     while (n--) {
    //         u8arr[n] = bstr.charCodeAt(n);
    //     }
    //     return new File([u8arr], filename, { type: mime });
    // }
    /* image cropping end */

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (title && dsc  && selectedFile && validFile) {
            confirmAlert({
                title: t("Confirm"),
                message: t("News_Add"),
                buttons: [
                    {
                        label: t("Yes"),
                        onClick: () => AddNews()
                    },
                    {
                        label: t("No"),
                        onClick: () => null
                    }
                ]
            });
        }
    }
    
    const AddNews = () => {
        NewsService.AddNewsUsers(title, dsc,selectedFile, status)
            .then((data) => {
                console.log(data)
                if (data.data.status.code === 0) {
                    setTitle('');
                    setDescription('');
                    ref.current.value = '';
                    setImagePreviewUrl(null);
                    setSelectedFile(null);
                    setStatus('');
                    dispatch(alertActions.success(data.data.status.message));
                    setTimeout(
                        () => (dispatch(alertActions.clear())),
                        2000
                    );
                    newsDisplay();
                    setSubmitted(false);
                }
            })
            .catch((error) => {
                console.log("Error", error)
            })
    }


    const deleteHandler = (_id) => {
        confirmAlert({
            title: t("Confirm"),
            message: t("News_Delete"),
            buttons: [
                {
                    label: t("Yes"),
                    onClick: () => DeleteNews(_id)
                },
                {
                    label: t("No"),
                    onClick: () => null
                }
            ]
        });
    }

    const DeleteNews = (_id) => {
        NewsService.DeleteNewsUsers(_id)
            .then((data) => {
                newsDisplay();
                dispatch(alertActions.success(data?.data?.status?.message));
                setTimeout(
                    () => (dispatch(alertActions.clear())),
                    2000
                );
            })
            .catch((error) => {
                console.log("Errorreg", error);
            })
    }


    const editNewsHandler = (id) => {
        setEditCrop(true);
        setIsOpened(true);
        setStoreId(id);
        NewsService.NewsUsersView(id)
            .then((data) => {
                if (data.data.status.code === 0) {
                    setTitle(data && data.data && data.data.data[0] ? data.data.data[0].news_title : '',);
                    setDescription(data && data.data && data.data.data[0] ? data.data.data[0].news_dsc : '',);
                    setStatus(data && data.data && data.data.data[0] ? data.data.data[0].status : '',)
                    setImagePreviewUrl(data && data.data && data.data.data[0] ? media_url + data.data.data[0].news_img : '');
                    // var newsimg = [];
                    // newsimg.push(data && data.data && data.data.data[0] ? data.data.data[0].news_img[0] : '');         
                    // setResult(newsimg);
                    setShow(!show)
                    setSelectedFile(data && data.data && data.data.data[0] ? data.data.data[0].news_img : '')
                }              
            })
            .catch((error) => {
                console.log("ERROR", error)
            })
    }


    const submitEdit = (e) => {
        console.log(result);
        e.preventDefault();
        confirmAlert({
            title: t("Confirm"),
            message: t("News_Update"),
            buttons: [
                {
                    label: t("Yes"),
                    onClick: () => UpdateNews()
                },
                {
                    label: t("No"),
                    onClick: () => null
                }
            ]
        });
    }

    const UpdateNews = () => {
        NewsService.NewsUpdate(storeId, title, dsc, selectedFile, status)
            .then((data) => {
                newsDisplay();
                if (data.data.status.code === 0) {
                    setTitle("")
                    setDescription("")
                    setImagePreviewUrl(null);
                    setStatus("")
                    setShow(false)
                    setIsOpened(false)
                    setSubmitted(false)                
                    setSelectedFile(null);            
                    ref.current.value = "";
                    dispatch(alertActions.success(data.data.status.message));
                    setTimeout(
                        () => (dispatch(alertActions.clear())),
                        2000
                    );
                }
            })
            .catch((error) => {
                console.log("Error", error)
            })
    }

    const handleCancel = () => {
        setIsOpened(false);
        setImagePreviewUrl(null)
        setTitle("")
        setDescription("")
        setStatus("")
        setShow(false);
        setSrcImg(null);
        setResult(null);
        setDisplayCrop(null);
        ref.current.value = "";
    }

    return {
        data,
        search,
        media_url,
        setImage,
        searchHandler,
        current,
        changeCurrentPage,
        deleteHandler,
        handleSubmit,
        changeHandler,
        selectedFile,
        title,
        dsc,
        fileName,
        changeImageHandler,
        editNewsHandler,
        limitFile,
        validFile,
        isOpened,
        imagePreviewUrl,
        ref,
        show,
        submitEdit,
        handleCancel,
        status,
        submitted,
        loader,
        srcImg,
        result,
        crop,
        setCrop,
        // getCroppedImg,
        editCrop,
        // displayCrop,
        
    }
}

export default useNewsHooks