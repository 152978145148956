import * as axios from "../network/Network";

const config = require("../network/api.json");

class FAQsService 
{
  static FAQs(search,pno) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.FAQList+`?search=${search}&page=${pno}`
    });
    return Promise.resolve(result);
  }

/**
 * Add FAQ
 */

  static FAQsAdd(add) {
    var bodyFormData = new FormData();
    bodyFormData.append("faq_header", add.faq_header);
    bodyFormData.append("faq_title", add.faq_title);
    bodyFormData.append("faq_dsc", add.faq_dsc);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.FAQAdd,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }


/**
 * FAQ View & Edit api
 */
 static FAQsView(id) {
  const result = axios.appRequest({
    method: axios.GET,
    url: config.user.FAQView+`/${id}`
  });
  return Promise.resolve(result);
}


/**
 * Update FAQ
 */
static FAQUpdate(add, status) {
  var bodyFormData = new FormData();
  bodyFormData.append("faq_header", add.faq_header);
  bodyFormData.append("faq_title", add.faq_title);
  bodyFormData.append("faq_dsc", add.faq_dsc);
  bodyFormData.append("status", status);
  const result = axios.appRequest({
    method: axios.PUT,
    url: config.user.FAQUpdate+`/${add.id}`,
    data: bodyFormData,
  });
  return Promise.resolve(result);
}

/**
 * Delete FAQ
 */
 static FAQDelete(id) {
  const result = axios.appRequest({
    method: axios.DELETE,
    url: config.user.FAQDelete+`/${id}`
  });
  return Promise.resolve(result);
}


}
export { FAQsService };
