import React from 'react'
import { logo } from '../../../assests/images/images'
import Header from '../../common/header/header'
import FooterContent from '../../common/footer/footer'
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import './ListWithdrawal.css';
import Pagination from "react-js-pagination";
import useListWithdrawalHooks from './useListWithdrawalHooks';
import { ListWithdrawalService } from '../../../services/ListWithdrawalService'
import { Loading } from '../../../assests/images/images'


const ListWithdrawal = (props) => {
    const { t } = props;
    const { data,
        current,
        withdrawStatus,
        loader,
        changeCurrentPage,
        searchHandler,
        rejectHandler,
        setStateHandler,
        approveHandler,
        hideButtonHandler
    } = useListWithdrawalHooks(props);

    const ListContents = () => {
        return (
            <div className="stylish_table flt">
                {!loader && <div className="load flt">
                    <img src={Loading} alt="Stylish" />
                </div>}
                <div className="stylish_top flt">
                    <div className="page_head flt mb-4">{t("Withdrawal_List")}</div>
                    <div className="row">
                        <div className="col-sm-8">
                            {data && data.data && data.data.docs &&
                                <ul className="stylish_tab flt">
                                    <li><Link to="/withdrawal-list?withdrawStatus=0" className={(withdrawStatus == 0 || !withdrawStatus ? 'active' : '')} onClick={() => setStateHandler(0)}>{t("Pending")}</Link></li>
                                    <li><Link to="/withdrawal-list?withdrawStatus=1" className={(withdrawStatus == 1 ? 'active' : '')} onClick={() => setStateHandler(1)}>{t("Approved")}</Link></li>
                                </ul>}
                        </div>
                        <div className="col-sm-4">
                            <div className="search_bar flt">
                                <input type="seach" placeholder="Search for name or email" onKeyUp={(e) => searchHandler(e)} className="form-control flt" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table convoc_table">
                        <thead>
                            <tr>
                                <th width="100px">{t("Sl_No")}</th>
                                <th>{t("Withdraw_Point")}</th>
                                <th>{t("Withdraw_Status")}</th>
                                <th>{t("Full_Name")}</th>
                                <th>{t("Email")}</th>
                                <th> {t("Bank_Details")}</th>
                                {withdrawStatus == 0 || !withdrawStatus ? <th>{t("Action")}</th> : ''}

                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && data.data && data.data.docs.length ?
                                    data.data.docs.map((list, index) => (
                                        <tr key={list._id}>
                                            <td>{20 * (data.data.page - 1) + index + 1}.</td>
                                            <td>{list?.withdrawPoint}</td>
                                            <td>{list?.withdrawStatus == 0 ? "Withdraw Pending" : list?.withdrawStatus == 1 ? "Withdraw Processed" : "Withdraw Rejected"}</td>
                                            <td>{list?.fullName}</td>
                                            <td>{list?.email}</td>
                                            <td>
                                            {t("First_Name")}:  {list?.bank_detail.first_name}<br/> {t("Sur_Name")}: {list?.bank_detail.sur_name}<br/> {t("Bank_Name")}: {list?.bank_detail.bank_name}<br/>{t("Branch_Name")}: {list?.bank_detail.brnach_name}<br/>{t("Account_Details")}: {list?.bank_detail.account_type}<br/>{t("Account_Number")}: {list?.bank_detail.account_number}</td>

                                            {list?.withdrawStatus == 0 ? <td>
                                                <button className='approve' onClick={() => approveHandler(list._id, 1)}>{t("Approve")}</button></td> : ""
                                            }

                                            
                                        </tr>
                                    )) : <tr><td colSpan={7}><div className='no_data flt' >No data</div></td></tr> 
                            }
                        </tbody>
                    </table>
                </div>


                <div className="row showrec">
                    <div className="col-sm-6">
                        {data && data.data &&
                            <div className="ShowRec flt"> Showing {current} to {data.data.limit} of {data.data.docs.length} entries</div>}
                    </div>
                    <div className="col-sm-6">
                        <div className="pagination_container flt">
                            {data && data.data &&
                                <Pagination
                                    activePage={current}
                                    itemsCountPerPage={20}
                                    totalItemsCount={data.data && data.data.totalPages * data.data.limit}
                                    pageRangeDisplayed={5}
                                    onChange={changeCurrentPage}
                                    firstPageText="First"
                                    lastPageText="Last"
                                    prevPageText="Previous"
                                    nextPageText="Next"
                                />

                            }

                        </div>
                    </div>

                </div>
            </div>
        )
    }


    return (
        <>
            <div className="main_container flt ">
                {Header()}
                <div className="page_container flt">
                    {ListContents()}
                </div>
                {FooterContent()}
            </div>

        </>
    )
}

export default withTranslation()(ListWithdrawal);