import React from 'react'
import { logo } from '../../../assests/images/images'
import { Default } from '../../../assests/images/images'
import Header from '../../common/header/header'
import FooterContent from '../../common/footer/footer'
import { withTranslation } from "react-i18next";
import './FavoriteFashionView.css';
import useFavoriteFashionViewHook from './useFavoriteFashionViewHook';
import { Link } from "react-router-dom";
import { Loading } from "../../../assests/images/images"
import { useNavigate } from "react-router-dom";

function FavoriteFashionView(props) {
    const { t } = props;
    const { image_url, data, loader, PageHandler, navigate } = useFavoriteFashionViewHook(t);
    

    const ViewUserContent = () => {
        return (
            <>
                <div className='viewuser_container flt'>
                    {!loader && <div className="load flt">
                        <img src={Loading} alt="Stylish" />
                    </div>}

                    <div className="page_head flt mb-4">{t("Fav_Fashion_Details")}</div> 
                    <div className='back_button1 flt'>
                        <button onClick={() => { navigate(-1) }}> <i class="fas fa-arrow-left"></i> {t("Back")}</button>
                    </div>
                 
                    {data && data.data &&
                        <div className='viewuser_cont flt'>
                            <div className='row mb-4'>
                                <div className='col-sm-4'>
                                    <div className="fashion_view_list flt" >
                                       <div className='row'>
                                        <div className='col-sm-3'>
                                            <div className='fash_img flt'>
                                                {
                                                    data.data[0].fashion_img ?
                                                        <img src={image_url + data.data[0].fashion_img} />
                                                        : <img src={Default} />
                                                }
                                            </div>
                                        </div>
                                        <div className='col-sm-9'>
                                            <ul className='styldetails2 flt'>
                                                {/* <li className='styhead2 flt'>{t("Fav_Fashion_Details")}</li> */}
                                                <li><b>{t("Name")} :</b> <span>{data.data[0].name}</span></li>
                                                <li><b>{t("Type")} :</b> <span>{data.data[0].type == "F" ? "Female" : data.data[0].type == "M" ? "Male" : ""}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    }

                </div>

            </>
        )
    }



    return (
        <>
            {Header()}
            <div className="page_container flt">
                {ViewUserContent()}
            </div>
            {FooterContent()}
        </>
    )
}

export default withTranslation()(FavoriteFashionView);