import React from 'react'
import { logo } from '../../../assests/images/images'
import { Default } from '../../../assests/images/images'
import Header from '../../common/header/header'
import FooterContent from '../../common/footer/footer'
import { withTranslation } from "react-i18next";
import './BlogView.css';
import useBlogViewHook from './useBlogViewHook';
import { Loading } from "../../../assests/images/images";

const BlogView=(props)=> {
    const { t } = props;
    const { data, loader, image_url, PageHandler,navigate } = useBlogViewHook(props);
    
    const ViewUserContent = () => {
        return (
            <>
                <div className='viewuser_container flt'>
                    {!loader&& <div className="load flt">
                        <img src={Loading} alt="Stylish" />
                    </div>}
                    <div className='stylist-blog flt'>
                    <div className='page_head flt mb-4'>{t("Stylist_Blog_Details")}</div>
                    <div className='back_button1 flt'>
                    <button onClick={() => { navigate(-1) }}> <i class="fas fa-arrow-left"></i> {t("Back")}</button>
                    </div>
                    </div>
                   
                    {data && data.data &&
                        <div className='viewuser_cont flt'>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <div className="styuser_list flt" >
                                        <div>
                                            {
                                                data.data.blog_img ? <img src={image_url + data.data.blog_img} alt="" />
                                                    : <img src={Default} alt="" />
                                            }

                                        </div>
                                        <div className="likes flt"> <i class="fas fa-thumbs-up"></i> <b>{t("Total_Likes")}: </b><span>{data.data.total_likes}</span></div>
                                    </div>

                                    <div className="styuser_listbl2 flt" >  
                                        <div className='styhead flt'>{t("User_Details")}</div>
                                        <div className='row'>
                                        <div className='col-sm-4'>
                                            <div className='blogimg flt'>
                                        {data.data.user_details.profile_pic ? <img src={image_url + data.data.user_details.profile_pic} alt="" />
                                                    : <img src={Default} alt="" />
                                                }
                                                </div>
                                        </div>
                                        <div className='col-sm-8'>
                                        <ul className='styldetails2 flt'>
                                        <li><b>{t("Name")}: </b><span>{data.data.user.full_name}</span></li>
                                            <li><b>{t("Email")}: </b><span>{data.data.user.email}</span> </li>
                                            <li><b>{t("Display_Name")}: </b>{data.data.user_details.display_name}</li>
                                        </ul>
                                        </div>
                                          </div>
                                    </div>
                                </div>
                                <div className='col-sm-8'>
                                    <div className="styuser_listbl4 flt" >
                                        <div className='titledesc flt'> <b>{t("Blog_Title")}</b> <br/> <span>{data.data.blog_title}</span></div>
                                    </div>
                                    <div className="styuser_listbl5 flt" >
                                        <div className='titledesc flt'> <b>{t("Blog_Description")}</b> <br/> <span>{data.data.blog_dsc}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }



    return (
        <>
            {Header()}
            <div className="page_container flt">
                {ViewUserContent()}
            </div>
            {FooterContent()}
        </>
    )
}

export default withTranslation()(BlogView);