import * as axios from "../network/Network";
const config = require("../network/api.json");


class FavoriteBrandListService 
{
  static FavoriteBrandList(search,pno) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.FavoriteBrandList+`?search=${search}&page=${pno}`
    });
    console.log(result.url);
    return Promise.resolve(result);
  }


  static FavoriteBrandView(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.FavoriteBrandView+`/${id}`
    });
    console.log(result.url);
    return Promise.resolve(result);
  }

/**
 * updating the values
 */
 static FavoriteBrandUpdate(id,name,status) {
  var bodyFormData = new FormData();
  bodyFormData.append("name", name);
  bodyFormData.append("status", status);
  const result = axios.appRequest({
    method:  axios.PUT,
    url: config.user.FavoriteBrandUpdate+`/${id}`,
    data: bodyFormData,
  });
  return Promise.resolve(result);
}

/**
 * Post(add) values 
 */

  static FavoriteBrandAdd(name, status)
   {
    var bodyFormData = new FormData();
    bodyFormData.append("name", name);
    bodyFormData.append("status", status);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.FavoriteBrandAdd,
      data: bodyFormData,
    });

    return Promise.resolve(result);
  }


  /** Delete data */

static FavoriteBrandDelete(id)
 {
  console.log(id);
  const result = axios.appRequest({
    method: axios.DELETE,
    url: config.user.FavoriteBrandDelete+`/${id}`,
  });
  return Promise.resolve(result);
}

}
export { FavoriteBrandListService };
