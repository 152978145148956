import React from 'react'
import { useState, useEffect,useCallback } from 'react';
import { UserManualService } from '../../../services/UserManualService';
import { alertActions } from "../../../actions/alert.actions";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useDispatch } from 'react-redux';

const useUserManualHooks = (props) => 
{
  const {t}=props
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [status, setStatus] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState(false);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState();
  const [videoFile, setVideoFile]= useState("");
  const [validFile, setValidFile] = useState(true); // file type must be video only
  const ref = React.useRef();
  const media_url = process.env.REACT_APP_MEDIA_DOMAIN;


  /** listing usermanual */
  useEffect(() => {
    ListUserManual(); 
  }, []);


  /** Render method to avoid page relaod */
  const ListUserManual = () => {
    UserManualService.ListUsermanual()
      .then((data) => 
      {
        setVideoFile(data && data.data && data.data.data && data.data.data.video_url ? media_url+ data.data.data.video_url:"")
        setLoader(true)
        setData(data.data);
        console.log(data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        setLoader(false)
      });
  }



/** handling the input */

  const changeFileHandler = useCallback(
    (e) => {
      if (e.target.files.length) {
        setVideoPreviewUrl(null);
        if (e.target.files[0].size > 1000000 * 50) {
          setSelectedFile(true);
          e.target.files = null;
        }
        else {
          setSelectedFile(e.target.files[0]);
          e.preventDefault();
          let reader = new FileReader();
          let file = e.target.files[0];
          let url=URL.createObjectURL(file)
          if (file.type.match('video/mp4'))
           {
            setValidFile(true);
            setVideoPreviewUrl(url)        
          }
          else{
            setValidFile(false)
          }
          reader.readAsDataURL(file);
        }
      }
    }
  ,
    [videoPreviewUrl, setVideoPreviewUrl,selectedFile, setSelectedFile],
  )



  /** updating the data */
  const UpdateHandler = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if(selectedFile&& validFile){
      confirmAlert({
        title: t("Confirm"),
        message: t("User_Manual"),
        buttons: [
            {
                label: t("Yes"),
                onClick: () => UpdateBlog()
            },
            {
                label: t("No"),
                onClick: () => null
            }
        ]
    });
    
    }
  }

  const UpdateBlog=()=>{
    UserManualService.UpdateUsermanual(selectedFile)
                .then((data) => {
                  if (data.data.status.code === 0) {
                    dispatch(alertActions.success(data.data.status.message));
                    setTimeout(
                      () => (dispatch(alertActions.clear())),
                      2000
                    );
                    ref.current.value='';
                    ListUserManual();
                  setSubmitted(false);
                  setSelectedFile(null);
                  // setVideoFile(data&&data.data &&data.data.data &&data.data.data.video_url ? media_url+ data.data.data.video_url:"")
                }  })
                .catch((error) => {
                  console.log("Errorreg", error);
                })
  }


  return {
    data, media_url, status, validFile, ref,loader, videoFile,UpdateHandler, selectedFile, submitted, changeFileHandler,videoFile,videoPreviewUrl
  }
}

export default useUserManualHooks