

import React from 'react'
import { logo } from '../../../assests/images/images'
import { Default } from '../../../assests/images/images'
import Header from '../../common/header/header'
import FooterContent from '../../common/footer/footer'
import { withTranslation } from "react-i18next";
import './ViewUsers.css';
import useViewUsersHook from './useViewUsersHook';
import { ViewUserService } from '../../../services/ViewUserService';
import { Link, useNavigate } from "react-router-dom";
import { Loading } from "../../../assests/images/images"
import { format } from 'date-fns';
import moment from "moment";

function ViewUsers(props) {
    const { t } = props;
    const { image_url, data, navigate, videoFile, loader, PageHandler, setStateHandler, changeStatus } = useViewUsersHook(t);
    console.log(data);

    const ViewUserContent = () => {
        return (
            <>
                <div className="viewNewsUsers_container flt">

                    {!loader &&
                        <div className="load flt">
                            <img src={Loading} alt="Stylish" />
                        </div>}
                    <div className="page_head flt mb-4">{t("User_Details")}</div>
                    <div className='back_button1 flt'>
                        <button onClick={() => { navigate(-1) }}> <i class="fas fa-arrow-left"></i> {t("Back")}</button>
                    </div>


                    {data && data.data &&
                        <div className="viewNews_cont flt">
                            <div className="row mb-4">
                                <div className="col-sm-8">
                                    <div className="styuser_list flt" >
                                        <div className='row'>
                                            <div className='col-sm-4'>

                                                <div className='stylimg flt'>
                                                    {
                                                        data.data.profile_photo ?
                                                            <img src={image_url + data.data.profile_photo} />
                                                            : <img src={Default} />
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-sm-8'>
                                                <ul className='styldetails2 flt'>
                                                    {/* <li className='styhead flt'>STYLIST USER DETAILS</li> */}

                                                    <li ><b>{t("Name")} :</b> {data.data.name}</li>
                                                    <li>
                                                        <b>{t("User_Type")} :</b> {data.data.user_type}
                                                    </li>
                                                    <li>
                                                        <b>{t("Email")} :</b>  {data.data.email}
                                                    </li>
                                                    <li><b>{t("Gender")} :</b> {data.data.gender}</li>
                                                    <li>
                                                        <b> {t("DOB")} :</b> {moment(data?.data?.date_of_birth).format('DD/MM/yyyy')}
                                                    </li>
                                                    <li>
                                                        <b>{t("Address")} :</b>   {data.data.address}
                                                    </li>
                                                    <li>
                                                        <b> {t("Prefecture")} :</b> {data.data.prefecture}
                                                    </li>
                                                    <li>
                                                        <b> {t("Display_Name")} :</b>  {data.data.display_name}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='underline flt'>
                                            <ul className='styldetails2 flt'>
                                                <li><b> {t("Favourite_Fashion")}: </b>
                                                    {
                                                        data.data.fav_fashion.map((el,index) => 
                                                            {return (<span key={el.id}>{(index?',': '')+el.name}</span>
                                                        )})}
                                                </li>

                                                <li> <b>{t("Fav_Brand")}: </b>
                                                       {
                                                        data.data.fav_brand.map((el,index) =>
                                                        {
                                                            return (<span key={el.id} dangerouslySetInnerHTML={{__html:(index?',': '')+el.name}}></span>
                                                        )})
                                                        }
                                                </li>
                                                <li><b>{t("Bid_Point")} : </b>{data.data.bid_point}</li>
                                                <li className='text2 flt'><b> {t("Bio")} : </b> {data.data.bio}</li>
                                            </ul>
                                        </div>
                                    </div>


                                </div>


                                <div className='col-sm-4'>
                                    <div className="styuser_list flt" >
                                        <ul className='styldetails2 flt'>
                                          
                                            <li className='styhead flt'>{t("Bank_Details")}</li>
                                            <li> <b>{t("First_Name")}: </b>{data.data.bank_details[0].first_name}</li>
                                            <li> <b>{t("Sur_Name")}: </b>{data.data.bank_details[0].sur_name}</li>
                                            <li> <b>{t("Bank_Name")}: </b>{data.data.bank_details[0].bank_name}</li>
                                            <li><b>{t("Branch_Name")}: </b>{data.data.bank_details[0].brnach_name}</li>
                                            <li><b>{t("Account_Details")}: </b>{data.data.bank_details[0].account_type}</li>
                                            <li><b>{t("Account_Number")}: </b>{data.data.bank_details[0].account_number}</li>
                                       
                                        </ul>
                                    </div>

                                    <div className="styuser_list1 flt" >
                                        <ul className='styldetails2 flt'>
                                            <li className='promvideo flt'>
                                                <b>{t("Promo_Video")}: </b>
                                                {
                                                    data.data.promotion_video ?
                                                        <video className='video_display' src={image_url + data.data.promotion_video} type="video/mp4" controls ></video>
                                                        : ""
                                                }
                                            </li>


                                            <li> <b> {t("Insta_Wrinkle")}:</b> {data.data.instagram_wrinkle}</li>
                                            <div><b> {t("Willingness_Propose")} :</b> <span>{data.data.willingness_propose}</span></div>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>


                    }
                </div >
            </>
        )
    }
    return (
        <>
            {Header()}
            <div className="page_container flt">
                {ViewUserContent()}
            </div>
            {FooterContent()}
        </>
    )
}

export default withTranslation()(ViewUsers);