import "./Login.css";
import React from "react";
import useLoginHooks from "./useLoginScreenHook";
import { logo } from "../../../assests/images/images";
import { withTranslation } from "react-i18next";

const Login = (props) => {
    const { t } = props;
    const {
        loader,
        userEmail,
        password,
        submitted,
        emailValid,
        handleUserEmailChange,
        handlePasswordChange,
        handleSubmit,
    } = useLoginHooks(t);



    const LoginHead = () => {
        return (
            <>
                <div className="login_logo flt">
                    <img src={logo}></img>
                </div>
                <div className="login_head flt">
                    {t("Login_Here")}
                </div>
            </>
        )
    }
    const FormContent = () => {
        return (
            <div className="form_box flt">
                <form>
                    <div className="form-group flt">
                        <label>{t("Email")}*</label>
                        <input type="email" name="email" value={userEmail} onChange={handleUserEmailChange} className="form-control" id="email" placeholder="Email" />
                        {submitted && !userEmail &&
                            <div className="invalid-feedback">{t("Email_ID_is_required")}</div>
                        }
                        {!emailValid && userEmail &&
                            <div className="invalid-feedback">{t("Valid_Email")}</div>
                        }
                    </div>
                    <div className="form-group flt">
                        <label>{t("Password")}*</label>
                        <input type="password" name="password" value={password} onChange={handlePasswordChange} className="form-control" id="pass" placeholder="Password" />
                        {submitted && !password &&
                            <div className="invalid-feedback">{t("Password_is_required")}</div>
                        }
                    </div>
                    <div className="btn flt">
                        <button onClick={handleSubmit}>{t("Submit")}</button>
                    </div>
                </form>
            </div>
        )
    }

    return (
        <>
            <div className="login_container flt">
                <div className="login_box">
                    {LoginHead()}
                    {FormContent()}
                </div>
            </div>
        </>
    )
}

export default withTranslation()(Login);
