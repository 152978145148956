
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FavoriteFashionListService } from '../../../services/FavoriteFashionListService';

function useFavoriteFashionViewHook(t) {
  const params = useParams();
  let id = params.favorite_id;
  const navigate = useNavigate();
  const image_url = process.env.REACT_APP_MEDIA_DOMAIN;
  const [data, setData] = useState("");
  const [loader,setLoader]=useState(false);

  useEffect(() => {
    FavoriteFashionListService.FavoriteFashionView(id)
      .then((data) => {
        setLoader(true);
        setData(data.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        setLoader(false);
      });
  }, []);

  return {
    data, image_url,loader,navigate
  }
}

export default useFavoriteFashionViewHook