import React from 'react';
import './RequestBidPoints.css'
import Header from '../../common/header/header'
import FooterContent from '../../common/footer/footer'
import { withTranslation } from "react-i18next";
import useRequestBidPointsHooks from './useRequestBidPointsHook';
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import { RequestBidPointsService } from '../../../services/RequestBidPointsService';
import { Loading } from "../../../assests/images/images"


const RequestBidPoints = (props) => {
    const { t } = props;
    const { data,
        current,
        show,
        changeStatus,
        loader,
        changeCurrentPage,
        searchHandler,
        setStateHandler,
        approveHandler,
        rejectHandler,
        hideButtonHandler
    } = useRequestBidPointsHooks(props);

   
    const TableContents = () => {
        return (
            <div className="stylish_table flt">
                {!loader && <div className="load flt">
                    <img src={Loading} alt="Stylish" />
                </div>}
                <div className="stylish_top flt">
                    <div className="page_head flt mb-4">{t("Request_Bidpoints")}</div>
                    <div className="row">
                        <div className="col-sm-8">
                            <ul className="stylish_tab flt">
                                <li><Link to="/request-bid-points?changeStatus=0" className={(changeStatus == 0 || !changeStatus ? 'active' : '')} onClick={() => setStateHandler(0)}>{t("Pending")}</Link></li>     
                                <li><Link to="/request-bid-points?changeStatus=1" className={(changeStatus == 1 ? 'active' : '')} onClick={() => setStateHandler(1)}>{t("Approved")}</Link></li>
                                <li><Link to="/request-bid-points??changeStatus=2" className={(changeStatus == 2 ? 'active' : '')} onClick={() => setStateHandler(2)}>{t("Rejected")}</Link></li>
                            </ul>
                        </div>
                        <div className="col-sm-4">
                            <div className="search_bar flt">
                                <input type="seach" placeholder="Search for name or email" onKeyUp={(e) => searchHandler(e)} className="form-control flt" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table convoc_table">
                        <thead>
                            <tr>
                                <th width="100px">{t("Sl_No")}</th>
                                <th >{t("Name")}</th>
                                <th >{t("Email")}</th>
                                <th >{t("Current_Points")}</th>
                                <th >{t("Requested_Points")}</th>
                                <th width="100px"> {t("Status")}</th>
                                {changeStatus == 0 || !changeStatus ? <th width="200px">{t("Action")}</th> : ''}

                            </tr>
                        </thead>
                        <tbody>
                            {data && data.data && data.data.docs.length ? data.data.docs.map((list, index) => (
                                <tr key={list._id}>
                                    <td>{20 * (data.data.page - 1) + index + 1}.</td>
                                    <td>{list?.user.full_name}</td>
                                    <td>{list?.user.email}</td>
                                    <td>{list?.old_bid_point}</td>
                                    <td>{list?.bid_point}</td>
                                    <td>{list?.admin_approved == 0 ? "Pending" : list?.admin_approved == 1 ? "Approved" : "Rejected"}</td>
                                    {(list?.admin_approved == 0) ?
                                        <td>
                                            <button className='approve' onClick={() => approveHandler(list._id, 1)}>{t("Approve")}</button>
                                            <button className='reject' onClick={() => rejectHandler(list._id, 2)}>{t("Reject")} </button>
                                        </td> : ''
                                    }
                                </tr>
                            )) : <tr><td colSpan={7}><div className='no_data flt' >No data</div></td></tr> 
                            }
                        </tbody>
                    </table>
                </div>

                <div className="row showrec">
                    <div className="col-sm-6">
                        {data && data.data &&
                            <div className="ShowRec flt"> Showing {current} to {data.data.limit} of {data.data.docs.length} entries</div>}
                    </div>
                    <div className="col-sm-6">
                        <div className="pagination_container flt">
                            {data && data.data &&
                                <Pagination
                                    activePage={current}
                                    itemsCountPerPage={20}
                                    totalItemsCount={data.data && data.data.totalPages * data.data.limit}
                                    pageRangeDisplayed={5}
                                    onChange={changeCurrentPage}
                                    firstPageText="First"
                                    lastPageText="Last"
                                    prevPageText="Previous"
                                    nextPageText="Next"
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <>
            <div className="main_container flt ">
                {Header()}
                <div className="page_container flt">
                    {TableContents()}
                </div>
                {FooterContent()}
            </div>
        </>
    )
}

export default withTranslation()(RequestBidPoints);
