
import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ListWithdrawalService } from '../../../services/ListWithdrawalService';
import { ViewUserService } from '../../../services/ViewUserService';

function useViewWithdrawalHooks(t) {
  const params = useParams();
  let id = params.viewer_id;
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const [loader, setLoader] = useState(false);


  /** Api call to view withdrawals */
  useEffect(() => {
    ListWithdrawalService.ViewWithdrawals(id)
      .then((data) => {
        setLoader(true);
        setData(data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        setLoader(false);
      });
  }, []);



  return {
    data, loader, navigate
  }
}

export default useViewWithdrawalHooks