import React from 'react'
import { logo } from '../../../assests/images/images'
import Header from '../../common/header/header'
import FooterContent from '../../common/footer/footer'
import { withTranslation } from "react-i18next";
import useUserManualHooks from './useUserManualHooks';
import './UserManual.css'
import {Loading} from '../../../assests/images/images'

const UserManual = (props) => {
    const { t } = props;
    const { data, media_url, videoFile,ref,status,loader,submitted,selectedFile, validFile,UpdateHandler,changeFileHandler,videoPreviewUrl } = useUserManualHooks(props);
    
    const UserContent = () => {
        return (
            <>
                <div className='Card-container flt'>
                {!loader && <div className="load flt">
                    <img src={Loading} alt="Stylish" />
                    </div>}
                    <div className='page_head flt'>{t("UserManual")}</div>
                    <div class="card flt">
                 
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className='videoupl flt'>
                                <label><b>{t("Upload_Video")} :</b></label><br></br>
                                        <button className="upload_btn"><i class="fas fa-file-upload"> <span>Upload file</span> </i>
                                            <input
                                                type="file"
                                                accept="video/*"
                                                onChange={changeFileHandler}
                                                name="video_file"
                                                ref={ref}
                                            /> </button>
                                    {submitted && !selectedFile &&
                                            <div className="invalid-feedback">{t("Video_Required")}</div>
                                        }
                                         {submitted && selectedFile && !validFile &&
                                            <div className="invalid-feedback">{t("Video_Only")}</div>
                                        }
                                    <div className="back_button">
                                        <button onClick={UpdateHandler}>{t("Update")}</button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
   
                                    {data && data.data &&
                                        <div className='Video flt'>
                                            {
                                                <video src={videoFile} type="video/mp4" controls>
                                                    {t("Browser_Support")}
                                                </video>
                                            }
                                        </div>
                                    } 
                            
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )

    }


    return (
        <>
            <div className="main_container flt ">
                {Header()}
                <div className="page_container flt">
                    {UserContent()}
                </div>
                {FooterContent()}
            </div>

        </>
    )
}

export default withTranslation() (UserManual);