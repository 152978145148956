import React from 'react';
import './links.css'
import { logo } from '../../../assests/images/images'
import Header from '../../common/header/header'
import FooterContent from '../../common/footer/footer'
import { withTranslation } from "react-i18next";
import useLinkHooks from './useLinkHooks';
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import { LinkService } from '../../../services/LinkService';
import { Default } from '../../../assests/images/images';
import { Loading } from "../../../assests/images/images"

const Links = (props) => {
    const { t } = props;
    const { 
        data,
        link,
        current,
        changeCurrentPage,
        searchHandler,
        deleteHandler,
        changeHandler,
        handleSubmit,
        editHandler,
        submitEdit,
        handleCancel,
        ref,
        isOpened,
       } = useLinkHooks(props);
       
        const TableContents = () => {
            return (
                <div className="stylish_table flt">
                    {/* {!loader && <div className="load flt">
                        <img src={Loading} alt="Stylish" />
                    </div>} */}
                    <div className="stylish_top mb-4 flt">
                        <div className="row">
                            <div className="col-sm-8">
                                <div className="page_head flt">{t("Links")}</div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="fashion_main flt">
                        <div className="row">
                            <div className="col-sm-3">
                                <form className="fashion_left flt">
                                    <div className="form-group flt">
                                        <label>{t("Link")}</label>
                                        <input type="text" name="link" value={link} className='form-control' ref={ref} onChange={changeHandler}  />
                                        
                                    </div>
                                    <div className="fashion_submit flt">
                                        {!isOpened && <button onClick={handleSubmit}>{t("Add")}</button>}
                                        {isOpened && <button onClick={submitEdit}>{t("Update")}</button>}
                                        {isOpened && <button onClick={handleCancel}>{t("Cancel")}</button>}
                                    </div>
                                </form>
                            </div>
                            <div className="col-sm-9">
                                <div className="fashion_right flt">
                                    {isOpened && <div className="table_overlay"></div>}
                                    <div className="table-responsive">
                                        <table className="table convoc_table">
                                            <thead>
                                                <tr>
                                                    <th width="100px">{t("Sl_No")}</th>
                                                    <th width="100px">{t("Links")}</th>
                                                    <th width="120px">{t("Action")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
    
                                                {data.data && data.data.docs.length ? data.data.docs.map((list, index) => (
                                                    <tr key={list._id}>
                                                        <td>{20 * (data.data.page - 1) + index + 1}.</td>
                                                        <td >{list?.link}</td>     
                                                         <td> 
                                                             <button className='edit' onClick={() => editHandler(list._id,list.status)}><i className="fas fa-pencil-alt"></i></button>
                                                            <button className='delete' onClick={() => deleteHandler(list._id)}><i className="fas fa-trash-alt"></i> </button>
                                                        </td>
    
                                                       
                                                    </tr>
                                                )) : <tr><td colSpan={3}><div className='no_data flt' >No data</div></td></tr> 
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                            
                                    <div className="row showrec">
                                        <div className="col-sm-6">
                                            {data && data.data &&
                                                <div className="ShowRec flt"> Showing {current} to {data.data.limit} of {data.data.docs.length} entries</div>}
    
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="pagination_container flt">
                                                {data && data.data &&
                                                    <Pagination
                                                        activePage={current}
                                                        itemsCountPerPage={20}
                                                        totalItemsCount={data.data && data.data.totalPages * data.data.limit}
                                                        pageRangeDisplayed={5}
                                                        onChange={changeCurrentPage}
                                                        firstPageText="First"
                                                        lastPageText="Last"
                                                        prevPageText="Previous"
                                                        nextPageText="Next"
                                                    />
    
                                                }
    
                                            </div>
                                        </div>
    
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    
    


    
    return (
        <>
             <div className="main_container flt ">
                {Header()}
                <div className="page_container flt">

                    {TableContents()}
                </div>
                {FooterContent()}
            </div>
        </>
    )
}

export default withTranslation()(Links);



