import * as axios from "../network/Network";
const config = require("../network/api.json");


class StylistBlogService 
{
  static ListBlog(pno,search,status) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.ListBlog+`?page=${pno}&search=${search}&status=${status}`
    });
    console.log(result.url);
    return Promise.resolve(result);
  }


  static ViewBlog(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.ViewBlog+`/${id}`
    });
    console.log(result.url);
    return Promise.resolve(result);
  }





  /** Delete data */

static DeleteBlog(id)
 {
  console.log(id);
  const result = axios.appRequest({
    method: axios.DELETE,
    url: config.user.DeleteBlog+`/${id}`,
  });
  return Promise.resolve(result);
}

}
export { StylistBlogService };
