
import React, { useEffect, useState } from "react";
import './StylishUsers.css'
import { logo } from '../../../assests/images/images'
import Header from '../../common/header/header'
import FooterContent from '../../common/footer/footer'
import { withTranslation } from "react-i18next";
import useStylishUsersHooks from './useStylishUsersHooks';
import Pagination from "react-js-pagination";
import { Link, useLocation } from "react-router-dom";
import { StylishUsersService } from '../../../services/StylishUsersService';
import { Loading } from "../../../assests/images/images"
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import moment from 'moment';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;


// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

const StylishUsers = (props) => {
    const [startDate, setStartDate] = useState(new Date());
    const { t } = props;
    const { data,
        current,
        show,
        changeStatus,
        loader,
        countData,
        DateRange,
        state,
        dates,
        changeCurrentPage,
        searchHandler,
        setStateHandler,
        approveHandler,
        rejectHandler,
        FromDate,
        ToDate,
        query,
        DateHandler,
        hideButtonHandler,
        TillDateHandler
    } = useStylishUsersHooks(props);


    const currentdate = new Date();
    const date = `${currentdate.getDate()}/${currentdate.getMonth() + 1}/${currentdate.getFullYear()}`;

    const TableContents = () => {
        return (
            <div className="stylish_table flt">
                {!loader && <div className="load flt">
                    <img src={Loading} alt="Stylish" />
                </div>}
                <div className="stylish_top flt">
                    <div className="tilldate flt">
                        <div className="page_head flt mb-4">{t("Stylist_Header")} </div>
                    </div>
                    <div className="DatePick flt"><span>{t("Custom_Date")}: </span></div>
                    <div className="DatePick flt">

                        <RangePicker onChange={DateHandler} disabledDate={(current) => {
                            let customDate = moment().add(1, 'days').format("YYYY-MM-DD");
                            return current && current > moment(customDate, "YYYY-MM-DD");
                        }} />
                    </div>

                    <div className="Dashboard flt">
                        <ul className="nav nav-pills dashboard_tab flt">
                            <li className="nav-item flt">
                                <div class="dashbox green flt">
                                    <div class="dash_icon">
                                        <span><i class="fas fa-users"></i></span>
                                    </div><div class="dashbox_cont">
                                        <div class="dashtag flt">{t("Total_Stylist")}</div>
                                        <div class="dash_number flt">{countData && countData.data && countData.data.total}</div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item flt">
                                <div class="dashbox blue flt">
                                    <div class="dash_icon">
                                        <span><i class="fas fa-female"></i></span>
                                    </div><div class="dashbox_cont">
                                        <div class="dashtag flt">{t("Female_Stylist")}</div>
                                        <div class="dash_number flt">{countData && countData.data && countData.data.female}</div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item flt">
                                <div class="dashbox yellow flt">
                                    <div class="dash_icon">
                                        <span><i class="fas fa-male"></i></span>
                                    </div><div class="dashbox_cont">
                                        <div class="dashtag1 flt">{t("Male_Stylist")}</div>
                                        <div class="dash_number1 flt">{countData && countData.data && countData.data.male}</div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item flt">
                                <div class="dashbox pink flt">
                                    <div class="dash_icon">
                                        <span><i class="fa fa-transgender" aria-hidden="true"></i></span>
                                    </div><div class="dashbox_cont">
                                        <div class="dashtag flt">{t("Other_Stylist")}</div>
                                        <div class="dash_number flt">{countData && countData.data && countData.data.others}</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-sm-8">
                            {data && data.data &&
                                <ul className="stylish_tab flt">
                                    <li><Link to="/stylish-users?changeStatus=0" className={(changeStatus == 0 || !changeStatus ? 'active' : '')} onClick={() => setStateHandler(0)}>{t("Pending")}</Link></li>
                                    <li><Link to="/stylish-users?changeStatus=1" className={(changeStatus == 1 ? 'active' : '')} onClick={() => setStateHandler(1)}>{t("Approved")}</Link></li>
                                    <li><Link to="/stylish-users?changeStatus=2" className={(changeStatus == 2 ? 'active' : '')} onClick={() => setStateHandler(2)}>{t("Rejected")}</Link></li>
                                </ul>}
                        </div>
                        <div className="col-sm-4">
                            <div className="search_bar flt">
                                <input type="seach" placeholder="Search for name or email" onKeyUp={(e) => searchHandler(e)} className="form-control flt" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table-responsive">
                    <table className="table convoc_table">
                        <thead>
                            <tr>
                                <th width="100px">{t("Sl_No")}</th>
                                <th>{t("Name")}</th>
                                <th>{t("Email")}</th>
                                <th>{t("Status")}</th>
                                <th width="180px">{t("Action")}</th>
                                <th width="80px">{t("View")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.data && data.data.docs.length ? data.data.docs.map((list, index) => (
                                <tr key={list._id}>
                                    <td>{20 * (data.data.page - 1) + index + 1}.</td>
                                    <td>{list?.name}</td>
                                    <td>{list?.email}</td>
                                    {/* {list?.email_status == 0 ? <i className="fa fa-times" aria-hidden="true"></i> : <i className="fa fa-check" aria-hidden="true"></i>} */}
                                    <td>{list?.approved_status == 0 ? "Pending" : list?.approved_status == 1 ? "Approved" : "Rejected"}</td>
                                    {list?.approved_status == 0 ?
                                        <td>
                                            <button className='approve' onClick={() => approveHandler(list._id, 1)}>Approve</button>
                                            <button className='reject' onClick={() => rejectHandler(list._id, 2)}>Reject </button> </td>
                                        : list?.approved_status == 1 ? <td> <button className='reject' onClick={() => rejectHandler(list._id, 2)}>Reject </button></td> : <td><button className='approve' onClick={() => approveHandler(list._id, 1)}>Approve</button></td>
                                    }
                                    <td><Link to={"/view-users/" + list._id} className='view'><i className="fas fa-eye"></i></Link></td>
                                </tr>
                            )) : <tr><td colSpan={6}><div className='no_data flt' >No data</div></td></tr> 
                            }
                        </tbody>
                    </table>
                </div> 
                <div className="row showrec">
                    <div className="col-sm-6">
                        {data && data.data &&
                            <div className="ShowRec flt"> Showing {current} to {data.data.limit} of {data.data.docs.length} entries</div>}
                    </div>
                    <div className="col-sm-6">
                        <div className="pagination_container flt">
                            {data && data.data &&
                                <Pagination
                                    activePage={current}
                                    itemsCountPerPage={20}
                                    totalItemsCount={data.data && data.data.totalPages * data.data.limit}
                                    pageRangeDisplayed={5}
                                    onChange={changeCurrentPage}
                                    firstPageText="First"
                                    lastPageText="Last"
                                    prevPageText="Previous"
                                    nextPageText="Next"
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <>
            <div className="main_container flt ">
                {Header()}
                <div className="page_container flt">
                    {TableContents()}
                </div>
                {FooterContent()}
            </div>
        </>
    )
}

export default withTranslation()(StylishUsers)
