import React from 'react';
import { useState, useEffect,useCallback } from 'react'
import { FavoriteFashionListService } from '../../../services/FavoriteFashionListService';
import { alertActions } from "../../../actions/alert.actions";
import { useDispatch } from 'react-redux';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';


const useFavoriteFashionListHooks=(props)=> {
  const { t } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [search, setSearch] = useState("");
  const [current, setcurrent] = useState(1);
  const [changeStatus, setChangeStatus] = useState(0);
  const [status, setStatus] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const [type, setType] = useState("");
  const [limitFile, setLimitFile] = useState(true); // file size limit 10 Mb
  const [validFile, setValidFile] = useState(true); // file type must be image only
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState();
  const [isOpened, setIsOpened] = useState(false);
  const [submitted, setSubmitted] = useState(false); //set submit
  const ref = React.useRef();
  const [loader,setLoader]=useState(false);



  const [add, setAdd] = useState({
    name: "",
    type: "",
    id: ""
  })
  const media_url = process.env.REACT_APP_MEDIA_DOMAIN;


  /**
   * Pagination
   */
  const changeCurrentPage = (page) => {
    setcurrent(page);
  }

  /**
   * Change handler for input
   */

  const changeHandler = useCallback(
    (e) => {
      const { name, value } = e.target;
      setAdd(add => ({ ...add, [name]: value }));
      if (e.target.name == 'type') {
        setType(e.target.value);
      }
  
      
      isNameValid();
      
    },
    [add,setAdd,],
  )


const isNameValid=()=>{
  let regex = /^[A-Za-z0-9]+$/;
      if(add.name && !regex.test(add.name)){
        return false;
      }
      else{
        return true;
      }
}



  /**Change handle for file input 
   * 
  */
  const changeFileHandler = useCallback(
    (e) => {
      if (e.target.files.length) {
        setImagePreviewUrl(null);
        if (e.target.files[0].size > 1000000 * 10) {
          setLimitFile(false);
          e.target.files = null;
          return false;
        }
        else {
          setSelectedFile(e.target.files[0]);
          e.preventDefault();
          let reader = new FileReader();
          let file = e.target.files[0];
          console.log(file);
          if (file.type.match('image/png') || file.type.match('image/jpg') || file.type.match('image/jpeg') || file.type.match('image/svg')) {
            reader.onloadend = () => {
              setImagePreviewUrl(reader.result);
              setValidFile(true)
            }
          }
          else {
            setValidFile(false)
          }
          reader.readAsDataURL(file);
        }
      }
    },[imagePreviewUrl, setImagePreviewUrl,selectedFile, setSelectedFile]
  )

  /**
   * List Api
   */
  const ListFavoriteFashion = () => {
    FavoriteFashionListService.FavoriteFashionList(search, changeStatus, current, '')
      .then((data) => {
        setLoader(true);
        setData(data.data);
        console.log(data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        setLoader(false);
      });
  }

  /**
   * Patch values to input fields on click of edit
   */
  const editHandler = (id) => {
    setIsOpened(true);
    FavoriteFashionListService.FavoriteFashionView(id)
      .then((data) => {
        console.log(data);
        if (data.data.status.code === 0) {
          setAdd({
            name: data && data.data && data.data.data[0] ? data.data.data[0].name : '',
            type: data && data.data && data.data.data[0] ? data.data.data[0].type : '',
            id: id
          });
          setType(data && data.data && data.data.data[0] ? data.data.data[0].type : '');
          setImagePreviewUrl(data && data.data && data.data.data[0] ? media_url + data.data.data[0].fashion_img : '')
           setSelectedFile(data && data.data && data.data.data[0] ? data.data.data[0].fashion_img : '')
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }

  /**
   * Create Favourite Fashion
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (add.name.trim() && add.type && selectedFile && validFile) {
      confirmAlert({
        title: t("Confirm"),
        message: t("Fashion_Add"),
        buttons: [
            {
                label: t("Yes"),
                onClick: () => AddFashion()
            },
            {
                label: t("No"),
                onClick: () => null
            }
        ]
    });
    }
  }


  const AddFashion=()=>{
    FavoriteFashionListService.FavoriteFashionAdd(add, selectedFile)
                .then((data) => {
                
                  if (data.data.status.code === 0) {
                    setAdd({
                      name: '',
                      type: '',
                      fashion_img: ''
                    });
                    setType('');
                    ref.current.value = "";
                    setSelectedFile(null);
                    setImagePreviewUrl(null);
                    dispatch(alertActions.success(data.data.status.message));
                    setTimeout(
                      () => (dispatch(alertActions.clear())),
                      2000
                    );
                    ListFavoriteFashion();
                    setSubmitted(false);
                  }
                })
                .catch((error) => {
                  console.log("Errorreg", error);
                })
  }


  useEffect(() => {
    ListFavoriteFashion();
  }, [current, search, changeStatus, refreshData]);

  /**
   * Delete Favourite Fashion
   */
  const deleteHandler = (_id) => {
    confirmAlert({
      title: t("Confirm"),
      message: t("Fashion_Delete"),
      buttons: [
          {
              label:t("Yes"),
              onClick: () =>DeleteFashion(_id)
          },
          {
              label: t("No"),
              onClick: () => null
          }
      ]
  });
  }

const DeleteFashion=(_id)=>{
  FavoriteFashionListService.FavoriteFashionDelete(_id)
  .then((data) => {
    ListFavoriteFashion();
    dispatch(alertActions.success(data.data.status.message));
    setTimeout(
      () => (dispatch(alertActions.clear())),
      2000
    );
  })
  .catch((error) => {
    console.log("Errorreg", error);
  })
}




  /**
   * Search
   */
  const searchHandler = useCallback((e) => {
    setSearch(e.target.value);
  },[search,setSearch])

  /**
   * Update Fashion
   */
  const submitEdit = (e) => {
    e.preventDefault();
    confirmAlert({
      title:t("Confirm"),
      message: t("Fashion_Update"),
      buttons: [
          {
              label: t("Yes"),
              onClick: () => UpdateFashion()
          },
          {
              label: t("No"),
              onClick: () => null
          }
      ]
  });
  }



const UpdateFashion=()=>{
  FavoriteFashionListService.FavoriteFashionUpdate(add, selectedFile)
              .then((data) => {
                ListFavoriteFashion();
                console.log(data)
                if (data.data.status.code === 0) {
                  setAdd({
                    name: '',
                    type: '',
                    fashion_img: ''
                  });
                  setType('');
                  ref.current.value = "";
                  setSelectedFile(null);
                  setImagePreviewUrl(null);
                  dispatch(alertActions.success(data.data.status.message));
                  setTimeout(
                    () => (dispatch(alertActions.clear())),
                    2000
                  );
                  setIsOpened(false);
                }
              })
              .catch((error) => {
                console.log("Errorreg", error);
              })
}


  /**
   * Cancel Fashion update
   */
  const handleCancel = (e) => {
    setIsOpened(false);
    setImagePreviewUrl(null);
    setAdd({
      name: '',
      type: '',
      fashion_img: ''
    });
    setType('');
    ref.current.value = "";
  }


  return {
    data,
    current,
    status,
    changeStatus,
    deleteHandler,
    setChangeStatus,
    changeCurrentPage,
    searchHandler,
    changeHandler,
    changeFileHandler,
    handleSubmit,
    editHandler,
    submitEdit,
    handleCancel,
    add,
    loader,
    type,
    limitFile,
    validFile,
    selectedFile,
    imagePreviewUrl,
    ref,
    isOpened,
    submitted,
    media_url,
    isNameValid
  };
}


export default useFavoriteFashionListHooks