import * as axios from "../network/Network";

const config = require("../network/api.json");

class ViewNewsUsersService
{
    static viewNewsUsers(id){
        const result=axios.appRequest({
            method:axios.GET,
            url:config.user.ViewNewsUsers+`/${id}`
        });
        console.log(result.url)
        return Promise.resolve(result)
    }
}

export {ViewNewsUsersService};