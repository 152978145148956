import { useState, useCallback } from "react";
import { userLogin } from "../../../actions/index";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { alertActions } from "../../../actions/alert.actions";


const useLoginHooks = (t) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState("notset");
  const [emailValid, setEmailValid] = useState(true);

  /**
   * handle change
   * @param {*} e 
   */
  const handleUserEmailChange = useCallback((e) => {
    setUserEmail(e.target.value);
    let pattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (userEmail && !pattern.test(userEmail)) {
      setEmailValid(false);
    }
    else {
      setEmailValid(true)
    } // Email Validation
  }, [userEmail, setUserEmail])



  const handlePasswordChange = useCallback(
    (e) => {
      setPassword(e.target.value);
    },
    [password, setPassword],
  )





  /**
   * api call
   * @param {*} e 
   */
  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    if (userEmail && password) {
      // setLoader("loading");
      dispatch(userLogin(userEmail, password))
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            setUserEmail("");
            setPassword("");
            setSubmitted(false);
            setLoader("isfull");
            navigate("/stylish-users");
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  }

  return {
    loader,
    userEmail,
    password,
    submitted,
    emailValid,
    handleUserEmailChange,
    handlePasswordChange,
    handleSubmit,
  };
};
export default useLoginHooks;
