import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { FAQheaderService } from '../../../services/FAQheaderService';
import { alertActions } from "../../../actions/alert.actions";
import { useDispatch } from 'react-redux';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Default } from '../../../assests/images/images';

const useFAQHeaderHook = (props) => {
  const { t } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [search, setSearch] = useState("");
  const [current, setcurrent] = useState(1);
  const [header, setHeader] = useState("");
  const [limitFile, setLimitFile] = useState(true); // file size limit 10 Mb
  const [validFile, setValidFile] = useState(true); // file type must be image only
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState();
  const [isOpened, setIsOpened] = useState(false);
  const [storeid, setStoreid] = useState("");
  const [submitted, setSubmitted] = useState(false); //set submit
  const ref = React.useRef();
  const [loader, setLoader] = useState(false);
  const media_url = process.env.REACT_APP_MEDIA_DOMAIN;




  const searchHandler = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search, setSearch],
  )


  const changeCurrentPage = (page) => {
    setcurrent(page);
  }


  const changeHandler = useCallback((e) => {
    if (e.target.name === 'faq_header') {
      setHeader(e.target.value);

    }
  }, [header, setHeader])

  const isNameValid = () => {
    let regex = /^[A-Za-z0-9]+$/;
    if (header && !regex.test(header)) {
      return false;
    }
    else {
      return true;
    }
  }


  /**Change handle for file input 
    */
  const changeFileHandler = useCallback(
    (e) => {
      if (e.target.files.length) {
        setImagePreviewUrl(null);
        if (e.target.files[0].size > 1000000 * 10) {
          setLimitFile(false);
          e.target.files = null;
          return false;
        }
        else {
          setSelectedFile(e.target.files[0]);
          e.preventDefault();
          let reader = new FileReader();
          let file = e.target.files[0];
          console.log(file);
          if (file.type.match('image/png') || file.type.match('image/jpg') || file.type.match('image/jpeg')) {
            reader.onloadend = () => {
              setImagePreviewUrl(reader.result);
              setValidFile(true)
            }
          }
          else {
            setValidFile(false)
          }
          reader.readAsDataURL(file);
        }

      }
    }, [imagePreviewUrl, setImagePreviewUrl, selectedFile, setSelectedFile]

  )


  useEffect(() => {
    ListFAQHeader();
  }, [search, current]);

  const ListFAQHeader = () => {
    FAQheaderService.FAQHeaderList(search, current)
      .then((data) => {
        setLoader(true);
        setData(data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        setLoader(false)
      });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (header.trim() && selectedFile && validFile) {
      confirmAlert({
        title: t("Confirm"),
        message: t("FAQH_Add"),
        buttons: [
          {
            label: 'Yes',
            onClick: () => AddFAQHeader()
          },
          {
            label: 'No',
            onClick: () => null
          }
        ]
      });

    }
  }


  const AddFAQHeader = () => {
    FAQheaderService.FAQHeaderAdd(header, selectedFile)
      .then((data) => {
        if (data.data.status.code === 0) {
          setHeader("");
          setSelectedFile(null);
          setImagePreviewUrl(null);
          dispatch(alertActions.success(data.data.status.message));
          setTimeout(
            () => (dispatch(alertActions.clear())),
            2000
          );
        }
        ListFAQHeader();
        ref.current.value = '';
        setSubmitted(false);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      })
  }

  const editHandler = (id) => {
    setIsOpened(true);
    FAQheaderService.FAQHeaderView(id).then((data) => {

      if (data.data.status.code === 0) {
        setHeader(data && data.data && data.data.data && data.data.data ? data.data.data.faq_header : '')
        setStoreid(id);
        setImagePreviewUrl(data && data.data && data.data.data && data.data.data && data.data.data.faq_img ? media_url + data.data.data.faq_img : Default)
        setSelectedFile(data && data.data && data.data.data && data.data.data ? data.data.data.faq_img : '')
      }
    }).catch((error) => {
      console.log("error", error);
    });
  }

  const submitEdit = (e) => {
    console.log(header)
    e.preventDefault();
    confirmAlert({
      title: t("Confirm"),
      message: t("FAQH_Update"),
      buttons: [
        {
          label: t("Yes"),
          onClick: () => UpdateFAQHeader()
        },
        {
          label: t("No"),
          onClick: () => null
        }
      ]
    });
  }


  const UpdateFAQHeader = () => {
    FAQheaderService.FAQHeaderUpdate(storeid, header, selectedFile)
      .then((data) => {
        ListFAQHeader();
        if (data.data.status.code === 0) {
          setHeader('');
          ref.current.value = "";
          setSelectedFile(null);
          setImagePreviewUrl(null);
          dispatch(alertActions.success(data.data.status.message));
          setTimeout(
            () => (dispatch(alertActions.clear())),
            2000
          );
          setIsOpened(false);
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      })
  }


  const handleCancel = () => {
    setIsOpened(false);
    setImagePreviewUrl(null);
    setHeader('');
    ref.current.value = "";
  }

  const deleteHandler = (_id) => {
    confirmAlert({
      title: t("Confirm"),
      message: t("FAQH_Delete"),
      buttons: [
        {
          label: 'Yes',
          onClick: () => DeleteFAQHeader(_id)
        },
        {
          label: 'No',
          onClick: () => null
        }
      ]
    });
  }


  const DeleteFAQHeader = (_id) => {
    FAQheaderService.FAQHeaderDelete(_id)
      .then((data) => {
        ListFAQHeader();
        dispatch(alertActions.success(data.data.status.message));
        setTimeout(
          () => (dispatch(alertActions.clear())),
          2000
        );
      })
      .catch((error) => {
        console.log("Errorreg", error);
      })
  }



  return {
    data,
    current,
    deleteHandler,
    changeCurrentPage,
    searchHandler,
    changeHandler,
    changeFileHandler,
    header,
    handleSubmit,
    editHandler,
    submitEdit,
    handleCancel,
    loader,
    limitFile,
    validFile,
    selectedFile,
    imagePreviewUrl,
    ref,
    isOpened,
    submitted,
    media_url,
    imagePreviewUrl,
    selectedFile,
    isNameValid
  }
}

export default useFAQHeaderHook;