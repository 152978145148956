import * as axios from "../network/Network";

const config = require("../network/api.json");

class UserManualService 
{
  static ListUsermanual() {

    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.ListUsermanual
    });
    console.log(result.url);
    return Promise.resolve(result);
  }
/** update video */
  static UpdateUsermanual(video_file) {
    console.log(video_file)
    var bodyFormData = new FormData();
    bodyFormData.append("video_file", video_file);
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.UpdateUsermanual,
      data: bodyFormData,
    });
    console.log(result.url)
    return Promise.resolve(result);
  }

  
}
export {UserManualService };