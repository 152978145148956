import React from "react";
import { Link } from "react-router-dom";
import FooterContent from "../../common/footer/footer";
import Header from "../../common/header/header";
import useViewNewsUsersHook from "./useViewNewsUsersHook";
import './ViewNewsUsers.css'
import { Default } from '../../../assests/images/images'
import { Loading } from "../../../assests/images/images"
import { t } from "i18next";

function ViewNewsUsers() {
    const { data, media_url, loader, navigate } = useViewNewsUsersHook()

    const viewNewsUserContent = () => {
        return (
            <>
                <div className="viewNewsUsers_container flt">
                    {!loader && <div className="load flt">
                        <img src={Loading} alt="Stylish" />
                    </div>}

                    <div className="page_head flt mb-4">{t("News_Details")}</div>
                    <div className='back_button1 flt'>
                        <button onClick={() => { navigate(-1) }}> <i class="fas fa-arrow-left"></i> {t("Back")}</button>
                    </div>

                    {data && data.data &&
                        <div className="viewNews_cont flt">
                            <div className="row mb-4">
                                <div className="col-sm-3">
                                    <div className="styuser_list flt" >
                                        <div className="newsimg flt">
                                            {
                                                data.data[0].news_img ? <img src={media_url + data.data[0].news_img} alt="" />
                                                    : <img src={Default} alt="" />
                                            }
                                        </div>
                                        <div className="likes flt"> <i class="fas fa-thumbs-up"></i> <b>{t("Total_Likes")}:  </b><span>{data.data[0].total_likes}</span></div>
                                    </div>
                                </div>

                                <div className="col-sm-9">
                                    <div className="styuser_list flt" >
                                        <div className="titledesc flt">  <b>{t("Title")} </b> <br /><span>{data.data[0].news_title}</span>
                                        </div></div>
                                    <div className="styuser_list11 flt" >
                                        <div className="titledesc flt">   <b>{t("Description")} </b><br /> <span>{data.data[0].news_dsc}</span>
                                        </div>  </div>

                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }
    return (
        <>
            {Header()}
            <div className="page_container flt">
                {viewNewsUserContent()}
            </div>
            {FooterContent()}
        </>
    )
}

export default ViewNewsUsers