
import { useState, useEffect, useCallback } from 'react'
import { FAQsService } from '../../../services/FAQsService';
import { FAQheaderService } from '../../../services/FAQheaderService';
import { alertActions } from "../../../actions/alert.actions";
import { useDispatch } from 'react-redux';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';



const useFAQsHooks=(props) => {
  const { t } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [headerData, setHeaderData] = useState([]);
  const [search, setSearch] = useState("");
  const [current, setcurrent] = useState(1);
  const [refreshData, setRefreshData] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [submitted, setSubmitted] = useState(false); //set submit
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState("");


  const [add, setAdd] = useState({
    faq_header: "",
    faq_title: "",
    faq_dsc: "",
    id: ""
  })

  /**
   * Pagination
   */
  const changeCurrentPage = (page) => {
    setcurrent(page);
  }


  /**
   * Change handler for input
   */
  const changeHandler = useCallback(
    (e) => {
      const { name, value } = e.target;
      setAdd(add => ({ ...add, [name]: value }));

      // select status
      if (e.target.name == 'status') {
        setStatus(e.target.value);
      }
    },
    [add, setAdd, status, setStatus],
  )



  useEffect(() => {
    ListFAQs();
  }, [search, current]);


  useEffect(() => {
    ListFAQHeader();
  }, []);




  const ListFAQHeader = () => {
    FAQheaderService.FAQHeaderList(search, current)
      .then((headerData) => {
        setHeaderData(headerData);
      });
  }


  /**
   * List Api
   */
  const ListFAQs = () => {
    FAQsService.FAQs(search, current)
      .then((data) => {
        setLoader(true);
        setData(data.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        setLoader(false);
      });
  }
  /**
   * Create FAQs
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (add.faq_title.trim() && add.faq_dsc.trim() && add.faq_header) {
      confirmAlert({
        title: t("Confirm"),
        message: t("FAQ_Add"),
        buttons: [
          {
            label: t("Yes"),
            onClick: () => AddFAQs()
          },
          {
            label: t("No"),
            onClick: () => null
          }
        ]
      });
    }
  }

  const AddFAQs = () => {
    FAQsService.FAQsAdd(add)
      .then((data) => {
        if (data.data.status.code === 0) {
          setAdd({
            faq_header: '',
            faq_title: '',
            faq_dsc: ''
          });
          dispatch(alertActions.success(data.data.status.message));
          setTimeout(
            () => (dispatch(alertActions.clear())),
            2000
          );
          ListFAQs();
          setSubmitted(false);
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      })
  }



  /**
   * Patch values to input fields on click of edit
   */
  const editHandler = (id) => {
    setIsOpened(true);
    FAQsService.FAQsView(id)
      .then((data) => {
        if (data.data.status.code === 0) {
          setAdd({
            faq_header: data && data.data && data.data.data ? data.data.data.faq_header : '',
            faq_title: data && data.data && data.data.data ? data.data.data.faq_title : '',
            faq_dsc: data && data.data && data.data.data ? data.data.data.faq_dsc : '',
            id: data && data.data && data.data.data ? data.data.data._id : ''
          });
          setStatus(data && data.data && data.data.data ? data.data.data.status : '');
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }



  /**
   * Delete Favourite Fashion
   */
  const deleteHandler = (_id) => {
    confirmAlert({
      title: t("Confirm"),
      message: t("FAQ_Delete"),
      buttons: [
        {
          label: t("Yes"),
          onClick: () => DeleteFAQ(_id)
        },
        {
          label: t("No"),
          onClick: () => null
        }
      ]
    });
  }


  const DeleteFAQ = (_id) => {
    FAQsService.FAQDelete(_id)
      .then((data) => {
        ListFAQs();
        dispatch(alertActions.success(data.data.status.message));
        setTimeout(
          () => (dispatch(alertActions.clear())),
          2000
        );
      })
      .catch((error) => {
        console.log("Errorreg", error);
      })
  }



  /**
   * Search
   */
  const searchHandler = useCallback(
    (e) => {
      setSearch(e.target.value);
      setcurrent("");
    },
    [search, setSearch],
  )


  /**
   * Update FAQs
   */
  const submitEdit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (add.faq_title.trim() && add.faq_dsc.trim() && add.faq_header) {
      confirmAlert({
        title: t("Confirm"),
        message: t("FAQ_Update"),
        buttons: [
          {
            label: t("Yes"),
            onClick: () => UpdateFAQ()
          },
          {
            label: t("No"),
            onClick: () => null
          }
        ]
      });
    }
  }


  const UpdateFAQ = () => {
    FAQsService.FAQUpdate(add, status)
      .then((data) => {
        if (data.data.status.code === 0) {
          setAdd({
            faq_header: '',
            faq_title: '',
            faq_dsc: ''
          });
          setStatus('');
          ListFAQs();
          dispatch(alertActions.success(data.data.status.message));
          setTimeout(
            () => (dispatch(alertActions.clear())),
            2000
          );
          setIsOpened(false);
          setSubmitted(false);
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      })
  }



  /**
   * Cancel Fashion update
   */
  const handleCancel = (e) => {
    e.preventDefault();
    setAdd({
      faq_header: '',
      faq_title: '',
      faq_dsc: ''
    });
    setStatus('');
    setIsOpened(false);
  }

  return {
    data,
    headerData,
    current,
    deleteHandler,
    changeCurrentPage,
    searchHandler,
    changeHandler,
    handleSubmit,
    editHandler,
    submitEdit,
    handleCancel,
    status,
    add,
    loader,
    isOpened,
    submitted
  };
}


export default useFAQsHooks