import * as axios from "../network/Network";

const config = require("../network/api.json");

class ViewUserService 
{
  static viewUsers(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.viewUsers+`/${id}`
    });
    console.log(result.url);
    return Promise.resolve(result);
  }
}
export { ViewUserService  };
