
import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ViewUserService } from '../../../services/ViewUserService';


function useViewUsersHook(t) {

  const params = useParams();
  let id = params.viewer_id;
  const navigate = useNavigate();
  const image_url = process.env.REACT_APP_MEDIA_DOMAIN;
  const [changeStatus, setChangeStatus] = useState(0);
  const [data, setData] = useState("");
  const [loader, setLoader] = useState(false)
  const [videoFile, setVideoFile] = useState("");
 


  useEffect(() => {
    ViewUserService.viewUsers(id)
      .then((data) => {
        setData(data.data);
        setLoader(true)
      })
      .catch((error) => {
        console.log("Errorreg", error);
        setLoader(false)
      });
  }, []);


 



  return {
    data, image_url, loader, videoFile, changeStatus,navigate
  }
}

export default useViewUsersHook