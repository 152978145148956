

import { useState, useEffect } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import {FavoriteBrandListService} from '../../../services/FavoriteBrandListService';


function useFavoriteBrandViewHooks(t) 
{
  /** */
    const params = useParams();
    let id = params.viewer_id;
    const navigate = useNavigate();
    const [data, setData] = useState("");
    const [loader,setLoader]=useState(false);



 /** View Favorite Brand */
 useEffect(() => 
 {
  FavoriteBrandListService.FavoriteBrandView(id)
          .then((data) => {
            setLoader(true);
            setData(data);
            console.log(data);
          })
          .catch((error) => {
            console.log("Errorreg", error);
            setLoader(false);
          });
      }, []);

  return {
    data,loader,navigate
  }
}

export default useFavoriteBrandViewHooks