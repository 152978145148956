import * as axios from "../network/Network";

const config = require("../network/api.json");

class FAQheaderService 
{


  /** List FAQHeader */

  static FAQHeaderList(search,pno) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.FAQHeaderList+`?search=${search}&page=${pno}`
    });

    console.log(result)
    return Promise.resolve(result);
  }


/** Add FAQ Header */
  static FAQHeaderAdd(faq_header,faq_img) {
    var bodyFormData = new FormData();
    bodyFormData.append("faq_header", faq_header);
    bodyFormData.append("faq_img", faq_img);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.FAQHeaderAdd,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }

/** Edit FQAHeader */

  static FAQHeaderView(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.FAQHeaderView+`/${id}`
    });
   
    return Promise.resolve(result);
  }
  

/** Update FAQ Header */
  static FAQHeaderUpdate(_id,header,faq_img) 
  {
    
  var bodyFormData = new FormData();
  bodyFormData.append("faq_header", header);

  if(faq_img){
    bodyFormData.append("faq_img", faq_img);
  }

  
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.FAQHeaderUpdate+`/${_id}`,
      data: bodyFormData,
    });
    return Promise.resolve(result);
  }


/** Delete FAQ Header */
  static FAQHeaderDelete(id)
 {
    const result = axios.appRequest({
      method: axios.DELETE,
      url: config.user.FAQHeaderDelete+`/${id}`
    });
    return Promise.resolve(result);
  }
}



export { FAQheaderService };