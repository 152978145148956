import React from 'react'
import { logo } from '../../../assests/images/images'
import { Default } from '../../../assests/images/images'
import Header from '../../common/header/header'
import FooterContent from '../../common/footer/footer'
import { withTranslation } from "react-i18next";
import './FAQsView.css';
import useFAQsViewHook from './useFAQsViewHook';
import { Link } from "react-router-dom";
import { Loading } from "../../../assests/images/images"


function FAQsView(props) {
    const { t } = props;
    const { data, loader, navigate } = useFAQsViewHook();
    console.log(data);

    const ViewUserContent = () => {
        return (
            <>
                <div className='viewuser_container flt'>
                    {!loader  && <div className="load flt">
                        <img src={Loading} alt="Stylish" />
                    </div>}
                     <div className='page1_head flt mb-4'>{t("FAQ")}<span className='lowercase'>s</span> {t("Details")}</div>
                    <div className='back_button1 flt'>
                        <button onClick={() => { navigate(-1) }}> <i class="fas fa-arrow-left"></i> {t("Back")}</button>
                    </div>
                   
                    {data && data.data &&
                        <div className='viewuser_cont flt'>
                            <div className='row mb-4'>
                                <div className='col-sm-8'>
                                    <div className="styuser_list1 flt" >
                                        <ul className='styldetails2 flt'>
                                            {/* <li className='styhead2 flt'>{t("FAQ")}<span className='lowercase'>s</span> {t("Details")}</li> */}
                                            <li><b>{t("Title")} :</b> <span>{data.data.faq_title}</span></li>
                                            <li><b>{t("Description")} :</b> <span>{data.data.faq_dsc}</span></li>
                                            <li><b>{t("Status")} :</b> <span>{data.data.status == 1 ? "Active" : data.data.status == 0 ? "Inactive" : ""}</span></li>
                                        </ul>
                                        {/* <Link to="/faq"> {t("Back")}</Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }



    return (
        <>
            {Header()}
            <div className="page_container flt">
                {ViewUserContent()}
            </div>
            {FooterContent()}
        </>
    )
}

export default withTranslation()(FAQsView);