import React from 'react'
import { logo } from '../../../assests/images/images'
import { Default } from '../../../assests/images/images'
import Header from '../../common/header/header'
import FooterContent from '../../common/footer/footer'
import { withTranslation } from "react-i18next";
import './FavoriteBrandView.css';
import { Link } from "react-router-dom";
import useFavoriteBrandViewHooks from './useFavoriteBrandViewHooks'
import { Loading } from "../../../assests/images/images"


function FavoriteBrandView(props) {
    const { t } = props;
    const { data, loader,navigate } = useFavoriteBrandViewHooks();


    const ViewWithdrawalContent = () => {
        console.log(data);
        return (
            <>
                <div className='Card-container flt'>
                    <div class="page_head flt mb-4">{t("Fav_Brand_Details")}
                    </div>
                    <div className='back_button1 flt'>
                    <button onClick={() => { navigate(-1) }}> <i class="fas fa-arrow-left"></i> {t("Back")}</button>
                    </div>

                    {!loader && <div className="load flt">
                        <img src={Loading} alt="Stylish" />
                    </div>}
                    {data && data.data && data.data.data && data.data.data[0] &&
                        <div className='row'>
                            <div className='col-sm-4'>
                            <div className="styuser_list1 flt" >
                            <ul className='styldetails4 flt'>
                            {/* <li className='styhead flt'>{t("Fav_Brand_Details")}</li> */}
                                    <li><b>{t("Name")}: </b><span>{data.data.data[0].name}</span></li>

                                    <li><b>{t("Status")}: </b><span>{data.data.data[0].status == 0 ? "Inactive" : data.data.data[0].status == 1 ? "Active" : ""}</span></li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    }

                </div>
            </>
        )
    }



    return (
        <>
            {Header()}
            <div className="page_container flt">
                {ViewWithdrawalContent()}
            </div>
            {FooterContent()}
        </>
    )
}

export default withTranslation() (FavoriteBrandView);