import * as axios from "../network/Network";

const config = require("../network/api.json");

class FavoriteFashionListService 
{
  static FavoriteFashionList(search,status,pno,type) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.FavoriteFashionList+`?search=${search}&status=${status}&page=${pno}&type=${type}`
    });
    console.log(result.url);
    return Promise.resolve(result);
  }

/**
 * Add Favourite Fashion
 */

  static FavoriteFashionAdd(add, fashion_img) {
    var bodyFormData = new FormData();
    bodyFormData.append("name", add.name);
    bodyFormData.append("type", add.type);
    bodyFormData.append("fashion_img", fashion_img);
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.FavoriteFashionAdd,
      data: bodyFormData,
    });
    console.log(result.url)
    return Promise.resolve(result);
  }
/**
 * status check api
 */
 static StatusCheck(id, status) {
  console.log(id);
  console.log(status);
  const result = axios.appRequest({
    method: axios.PUT,
    url: config.user.status_check+`/${id}`,
    data:{status}
  });
  return Promise.resolve(result);
}
 

/**
 * Fashion View api
 */
 static FavoriteFashionView(id) {
  const result = axios.appRequest({
    method: axios.GET,
    url: config.user.FavoriteFashionView+`/${id}`
  });
  console.log(result.url);
  return Promise.resolve(result);
}


/**
 * Fashion Edit api
 */
 static FavoriteFashionEdit(id) {
  const result = axios.appRequest({
    method: axios.GET,
    url: config.user.FavoriteFashionEdit+`/${id}`
  });
  console.log(result.url);
  return Promise.resolve(result);
}

/**
 * Submit Edit Fashion
 */
static FavoriteFashionUpdate(add, fashion_img) {
  console.log(add)
  var bodyFormData = new FormData();
  bodyFormData.append("name", add.name);
  bodyFormData.append("type", add.type);
  bodyFormData.append("fashion_img", fashion_img);
  const result = axios.appRequest({
    method: axios.PUT,
    url: config.user.FavoriteFashionUpdate+`/${add.id}`,
    data: bodyFormData,
  });
  console.log(result.url)
  return Promise.resolve(result);
}

/**
 * Delete Fashion
 */
 static FavoriteFashionDelete(id) {
  const result = axios.appRequest({
    method: axios.DELETE,
    url: config.user.FavoriteFashionDelete+`/${id}`
  });
  console.log(result.url);
  return Promise.resolve(result);
}


}
export { FavoriteFashionListService };
