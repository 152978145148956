import React from 'react';
import { useState, useEffect, useCallback } from 'react'
import { LinkService } from '../../../services/LinkService'
import { alertActions } from "../../../actions/alert.actions";
import { useDispatch } from 'react-redux';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';



const useLinkHooks = (props) => {
  const { t } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [search, setSearch] = useState("");
  const [current, setCurrent] = useState("");
  const [status, setStatus] = useState("");
  const [link, setLink] = useState("");
  const [submitted, setSubmitted] = useState("");
  const [isOpened, setIsOpened] = useState(false);
  const [savMyId, setSaveMyId] = useState("");
  const ref = React.useRef();
  const [loader, setLoader] = useState(false);

  const changeHandler = useCallback(
    (e) => {
      if (e.target.name == 'link') {
        setLink(e.target.value);
      }

    },
    [link, setLink],
  )



  const changeCurrentPage = (page) => {
    setCurrent(page);
  }

  const searchHandler = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search, setSearch],
  )



  /**
   * List Api
   */
  const ListLinks = () => {
    LinkService.LinkList(status, current, search)
      .then((data) => {
        setData(data.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);

      });
  }


  useEffect(() => {
    ListLinks();
  }, [status, current, search]);




  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (link) {
      confirmAlert({
        title: t("Confirm"),
        message: t("Link_Add"),
        buttons: [
          {
            label: t("Yes"),
            onClick: () => AddLink()
          },
          {
            label: t("No"),
            onClick: () => null
          }
        ]
      });
    }
  }

  const AddLink = () => {
    LinkService.LinkAdd(link)
      .then((data) => {
        if (data.data.status.code === 0) {
          setLink('');
          ref.current.value = "";
          dispatch(alertActions.success(data.data.status.message));
          setTimeout(
            () => (dispatch(alertActions.clear())),
            2000
          );
          ListLinks();
          setSubmitted(false);
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      })
  }


  const deleteHandler = (_id) => {
    confirmAlert({
      title: t("Confirm"),
      message: t("Link_Delete"),
      buttons: [
        {
          label: t("Yes"),
          onClick: () => DeleteLink(_id)
        },
        {
          label: t("No"),
          onClick: () => null
        }
      ]
    });
  }

  const DeleteLink = (_id) => {
    LinkService.LinkDelete(_id)
      .then((data) => {
        ListLinks();
        dispatch(alertActions.success(data.data.status.message));
        setTimeout(
          () => (dispatch(alertActions.clear())),
          2000
        );
      })
      .catch((error) => {
        console.log("Errorreg", error);
      })
  }


  const editHandler = (id, val) => {
    setStatus(val);
    setSaveMyId(id);
    setIsOpened(true);
    LinkService.LinkView(id)
      .then((data) => {
        if (data.data.status.code == 0) {
          setLink(data && data.data && data.data.data ? data.data.data.link : '');
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }


  const submitEdit = (e) => {
    e.preventDefault();
    confirmAlert({
      title: t("Confirm"),
      message: t("Link_Update"),
      buttons: [
        {
          label: t("Yes"),
          onClick: () => UpdateLink()
        },
        {
          label: t("No"),
          onClick: () => null
        }
      ]
    });
  }



  const UpdateLink = () => {
    LinkService.LinkUpdate(link, savMyId, status)
      .then((data) => {
        ListLinks();
        console.log(data)
        if (data.data.status.code === 0) {
          setLink('')
          ref.current.value = "";
          dispatch(alertActions.success(data.data.status.message));
          setTimeout(
            () => (dispatch(alertActions.clear())),
            2000
          );
          setIsOpened(false);
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      })
  }


  const handleCancel = (e) => {
    setIsOpened(false);
    setLink('');
    ref.current.value = "";
  }



  return {
    data,
    changeHandler,
    handleSubmit,
    deleteHandler,
    searchHandler,
    handleSubmit,
    submitEdit,
    handleCancel,
    changeCurrentPage,
    isOpened,
    submitted,
    ref,
    editHandler,
    link,
    search
    , savMyId,
    loader,



  };
}



export default useLinkHooks