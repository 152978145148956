import React from "react";
import { logo } from "../../../assests/images/images";
import './header.css';
import { Link } from "react-router-dom";
import { alertActions } from "../../../actions/alert.actions";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../../actions";
import { useNavigate } from "react-router-dom";

const Header = () => {
    let history = useNavigate();
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(userLogout());
        history("/");
        dispatch(alertActions.success("Logout Success"));
    };
    return (<div className="header_container flt">
        <div className="logo">
            <img src={logo} />
        </div>
        <ul className="menuH">
            <li><a href="#">Users</a>
                <ul className="dropdown">
                    <li><Link to="/stylish-users">Stylist User</Link></li>
                    <li><Link to="/basic-users">Basic User</Link></li>
                </ul>
            </li>
            <li><Link to="/request-bid-points">Bid Points</Link></li>
            <li><Link to="/withdrawal-list">Withdrawals</Link></li>
            <li><Link to="/usermanual">Usermanual</Link></li>
            <li><Link to="/favorite-fashion">Favourite Fashion</Link></li>
            <li><Link to="/favorite-brand">Favourite Brand</Link></li>
            <li><Link to="/news">Fashion News</Link></li>
            <li><Link to="/faq-header">FAQ Header</Link></li>
            <li><Link to="/faq">FAQs</Link></li>
            <li><Link to="/stylistblog">Stylist Blog</Link></li>
            <li><Link to="/link-list">Links</Link></li>
            <li><button className="logout flt" onClick={handleLogout}><i class="fas fa-sign-out-alt"></i></button></li>
        </ul>
    </div>);
}

export default Header