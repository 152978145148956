import React from 'react'
import { useState, useEffect, useCallback} from 'react'
import { StylishUsersService } from '../../../services/StylishUsersService';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { alertActions } from "../../../actions/alert.actions";
import { useDispatch } from 'react-redux';
import { confirmAlert } from "react-confirm-alert";
import { useLocation } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';

const useStylishUsersHooks=(props)=> {
  const { t } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [search, setSearch] = useState("");
  const [current, setcurrent] = useState(1);
  const [changeStatus, setChangeStatus] = useState(0);
  const [status, setStatus] = useState("");
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [FromDate, setFromDate] = useState("");
  const [ToDate, setToDate] = useState("")
  const [countData, setCountData] = useState('');

  const search1 = useLocation();
  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const changeCurrentPage = (page) => {
    setcurrent(page);
  }

  const searchHandler = useCallback(
    (e) => {
      setSearch(e.target.value);
      setcurrent("");
    }
  ,
    [search,setSearch],
  )




  const ListStylish = () => {
    const queryparams = new URLSearchParams(search1.search);
    const value = queryparams.get("changeStatus");
    setChangeStatus(value);
    StylishUsersService.StylishUsers(search, value ? value : 0, current, FromDate, ToDate)
      .then((data) => {
        setLoader(true);
        setData(data.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        setLoader(false);
      });
  }


  const CountDataHandler = () => {
    StylishUsersService.StylistUsersCount(FromDate, ToDate)
      .then((data) => {
        setLoader(true);
        setCountData(data.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        setLoader(false)
      });
  }



  useEffect(() => {
    ListStylish();
    CountDataHandler();
  }, [search, current, FromDate, ToDate]);


  const DateHandler = (date, dateString) => {
    setFromDate(dateString[0]);
    setToDate(dateString[1]);
  }



  const approveHandler = (_id, status) => {
    confirmAlert({
      title: t("Confirm"),
      message: t("Stylist_Approve"),
      buttons: [
        {
          label: t("Yes"),
          onClick: () => ApproveStylist(_id,status)
        },
        {
          label: t("No"),
          onClick: () => null
        }
      ]
    });
  }

const ApproveStylist=(_id,status)=>{
  StylishUsersService.StatusCheck(_id, status).then((data) => {
    if (data.data.status.code === 0) {
      dispatch(alertActions.success(data.data.status.message));
      setTimeout(
        () => (dispatch(alertActions.clear())),
        2000
      );
    }
    setStatus(data.data);
    ListStylish();
  })
    .catch((error) => {
      console.log("Errorreg", error);
    })
}



  const rejectHandler = (_id,status) => {
    confirmAlert({
      title: t("Confirm"),
      message: t("Stylist_Reject"),
      buttons: [
        {
          label: t("Yes"),
          onClick: () => RejectStylist(_id,status)
        },
        {
          label: t("No"),
          onClick: () => null
        }
      ]
    });
  }


  const RejectStylist=(_id,status)=>{
    StylishUsersService.StatusCheck(_id, status).then((data) => {
      if (data.data.status.code === 0) {
        dispatch(alertActions.success(data.data.status.message));
        setTimeout(
          () => (dispatch(alertActions.clear())),
          2000
        );
      }
      setStatus(data.data);
      ListStylish();
    })
      .catch((error) => {
        console.log("Errorreg", error);
      })
  }




  const setStateHandler = (val) => {
    if (val === 0) {
      setChangeStatus(0)
      
    }
    else if (val === 1) {
      setChangeStatus(1)
    }
    else {
      setChangeStatus(2)

    }
    StylishUsersService.StylishUsers(search, val, current, FromDate, ToDate)
      .then((data) => {
        setLoader(true)
        setcurrent(1);
        setData(data.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        setLoader(false)
      });
  }


  return {
    data,
    current,
    status,
    show,
    changeStatus,
    loader,
    FromDate, ToDate,
    countData,
    approveHandler,
    setChangeStatus,
    changeCurrentPage,
    searchHandler,
    rejectHandler,
    setStateHandler,
    DateHandler
  };
}


export default useStylishUsersHooks