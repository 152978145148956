import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { FavoriteBrandListService } from '../../../services/FavoriteBrandListService';
import { useDispatch } from 'react-redux';
import { alertActions } from "../../../actions/alert.actions";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { withTranslation } from "react-i18next";
// import {RichTextEditorComponent} from '@syncfusion/ej2-react-richtexteditor';


const useFavoriteBrandListHooks = (props) => {
  const { t } = props
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [search, setSearch] = useState("");
  const [current, setcurrent] = useState(1);
  const [changeStatus, setChangeStatus] = useState(0);
  const [selectedFile, setSelectedFile] = useState();
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [submitted, setSubmitted] = useState("");
  const [storeid, setstoreid] = useState("");
  const [isOpened, setIsOpened] = useState(false);
  const InputRef = React.useRef(0);
  const [loader, setLoader] = useState(false);


  /**including media(video url) */
  const media_url = process.env.REACT_APP_MEDIA_DOMAIN;


  /**pagination */
  const changeCurrentPage = (page) => {
    setcurrent(page);
  }


  /**taking input from input fields */
  const changeHandler = useCallback(
    (e) => {
      if (e.target.name == 'name') {
        setName(e.target.value);
        // isNameValid();
      }
      if (e.target.name == 'status') {
        setStatus(e.target.value);
      }
    },
    [name, setName, status, setStatus],
  )


  // const isNameValid = () => {
  //   let regex = /^[A-Za-z0-9 ]+$/;
  //   if (name && !regex.test(name)) {
  //     return false;
  //   } 
  //   return true;
  // }


  /**Search */
  const searchHandler = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search, setSearch],
  )


  useEffect(() => {
    // including render function
    ListFavoritebrand();
  }, [search, current]);



  // to update changes without refreshing page, use the function wherever we need it
  const ListFavoritebrand = () => {
    FavoriteBrandListService.FavoriteBrandList(search, current)
      .then((data) => {
        setLoader(true)
        setData(data.data);
        console.log(data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        setLoader(false)
      });
  }



  /**post values */
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (name.trim()) {
      confirmAlert({
        title: t("Confirm"),
        message: t("Brand_Add"),
        buttons: [
          {
            label: t("Yes"),
            onClick: () => AddBrand()
          },
          {
            label: t("No"),
            onClick: () => null
          }
        ]
      });
    }
  }



  const AddBrand = () => {
    FavoriteBrandListService.FavoriteBrandAdd(name, status)
      .then((data) => {
        if (data.data.status.code === 0) {
          setName("");
          console.log(name);
          dispatch(alertActions.success(data.data.status.message));
          setTimeout(
            () => (dispatch(alertActions.clear())),
            2000
          );
        }
        ListFavoritebrand();
        setSubmitted(false);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      })
  }


  /**delete values */
  const deleteHandler = (_id) => {
    confirmAlert({
      title: t("Confirm"),
      message: t("Brand_Delete"),
      buttons: [
        {
          label: t("Yes"),
          onClick: () => DeleteBrand(_id)
        },
        {
          label: t("No"),
          onClick: () => null
        }
      ]
    });
  }


  const DeleteBrand = (_id) => {
    FavoriteBrandListService.FavoriteBrandDelete(_id)
      .then((data) => {
        if (data.data.status.code === 0) {
          dispatch(alertActions.success(data.data.status.message));
          setTimeout(
            () => (dispatch(alertActions.clear())),
            2000
          );
        }
        // including render function
        ListFavoritebrand();
      })
      .catch((error) => {
        console.log("Errorreg", error);
      })
  }



  /** editing values*/
  const editHandler = (_id, names, status) => {
  //   const ename=  new Sanitizer.sanitize(names)
  //  setName(ename)
  // const fragment = toHtml(names);
  // console.log(fragment);
    setName(names);
    setIsOpened(true);
    setstoreid(_id);
    
    // setName(name.createWithContent(
    //   ContentState.createFromBlockArray(
    //     convertFromHTML(names))));
    setStatus(status);
    setSelectedFile(true);
  }





  /**update values */
  const updateHandler = (e) => {
    e.preventDefault();
    confirmAlert({
      title: t("Confirm"),
      message: t("Brand_Update"),
      buttons: [
        {
          label: t("Yes"),
          onClick: () => UpdateBrand()
        },
        {
          label: t("No"),
          onClick: () => null
        }
      ]
    });
  }


  const UpdateBrand = () => {
    FavoriteBrandListService.FavoriteBrandUpdate(storeid, name, status)
      .then((data) => {
        ListFavoritebrand();
        if (data.data.status.code === 0) {
          setName("")
          setStatus("")
          setSubmitted(false)
          setIsOpened(false)
          dispatch(alertActions.success(data.data.status.message));
          setTimeout(
            () => (dispatch(alertActions.clear())),
            2000
          );
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      })
  }



  // /**update values */
  // const updateHandler = (e) => {
  //   //setLoader("loading")
  //   e.preventDefault();
  //   confirmAlert({
  //     title: "Please confirm !!",
  //     message: "Are You sure want to Update this Brand ?",
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: () => FavoriteBrandListService.FavoriteBrandUpdate(storeid, name, status)
  //           .then((data) => {
  //             //setLoader("isfull")
  //             if (data.data.status.code === 0) {
  //               setName("");
  //               setStatus("");
  //               dispatch(alertActions.success(data.data.status.message));
  //               setTimeout(
  //                 () => (dispatch(alertActions.clear())),
  //                 2000
  //               );
  //             }
  //             ListFavoritebrand();
  //             setSubmitted(false);
  //             setIsOpened(false)
  //           })
  //           .catch((error) => {
  //             console.log("Errorreg", error);
  //             //setLoader("isempty")
  //           })
  //       },
  //       {
  //         label: 'No',
  //         onClick: () => null
  //       }
  //     ]
  //   });
  // }



  /**Cancel update */

  const cancelHandler = (e) => {
    e.preventDefault();
    setName("");
    setStatus("");
    setIsOpened(false);
  }

  return {
    data,
    current,
    status,
    submitted,
    changeStatus,
    deleteHandler,
    setChangeStatus,
    changeHandler,
    changeCurrentPage,
    searchHandler,
    handleSubmit,
    updateHandler,
    cancelHandler,
    name,
    editHandler,
    selectedFile,
    InputRef,
    loader,
    isOpened,
    media_url,

  };
}
export default useFavoriteBrandListHooks