import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { StylistBlogService } from '../../../services/StylistBlogService';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { alertActions } from "../../../actions/alert.actions";
import { useDispatch } from 'react-redux';
import { confirmAlert } from "react-confirm-alert";
import { useLocation } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';

function useStylistBlogHook(t) {
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [search, setSearch] = useState("");
  const [current, setcurrent] = useState(1);
  const [status, setStatus] = useState("");
  const [loader, setLoader] = useState(false);
  const media_url = process.env.REACT_APP_MEDIA_DOMAIN;


  const changeCurrentPage = (page) =>
   {
    setcurrent(page);
  }


  useEffect(() => {
    ListStylishBlog();
  }, [current, search, status]);


  const ListStylishBlog = () => {
    StylistBlogService.ListBlog(current, search, status)
      .then((data) => {
        setLoader(true)
        setData(data.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        setLoader(false);
      });
  }



  /**
   * Delete Blog
   */
  const deleteHandler = (_id) => {
    confirmAlert({
      title: t("Confirm"),
      message: t("Blog_Delete"),
      buttons: [
        {
          label: t("Yes"),
          onClick: () => DeleteBlog(_id)
        },
        {
          label:t("No"),
          onClick: () => null
        }
      ]
    });
  }

  const DeleteBlog = (_id) => {
    StylistBlogService.DeleteBlog(_id)
      .then((data) => {
        ListStylishBlog();
        dispatch(alertActions.success(data.data.status.message));
        setTimeout(
          () => (dispatch(alertActions.clear())),
          2000
        );
      })
      .catch((error) => {
        console.log("Errorreg", error);
      })
  }



  const searchHandler = useCallback(
    (e) => {
      setSearch(e.target.value);
      setcurrent("");
    }  ,
    [search, setSearch],
  )


  return {
    data,
    loader,
    current,
    status,
    media_url,
    deleteHandler,
    searchHandler,
    changeCurrentPage
  };
}


export default useStylistBlogHook