import * as axios from "../network/Network";

const config = require("../network/api.json");

class ListWithdrawalService 
{
  static ListWithdrawal(status, search) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.Withdrawal+`?status=${status}&search=${search}`
    });
    return Promise.resolve(result);
  }


  static ViewWithdrawals(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.ViewWithdrawals+`/${id}`
    });
    console.log(result.url);
    return Promise.resolve(result);
  }

  /** Update withdrawal */
  static WithdrawalUpdate(id, status) {
    console.log(id);
    console.log(status);
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.WithdrawalUpdate+`/${id}`,
      data:{status}
    });
    return Promise.resolve(result);
  }
   
}
export { ListWithdrawalService };
