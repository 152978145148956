import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { RequestBidPointsService } from '../../../services/RequestBidPointsService';
import { alertActions } from "../../../actions/alert.actions";
import { useDispatch } from 'react-redux';
import { confirmAlert } from "react-confirm-alert";
import { useLocation } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';

const useRequestBidPointsHooks = (props) => {
  const { t } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [search, setSearch] = useState("");
  const [current, setcurrent] = useState(1);
  const [changeStatus, setChangeStatus] = useState(0);
  const [status, setStatus] = useState("");
  const [show, setShow] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [loader, setLoader] = useState(false);

  const changeCurrentPage = (page) => {
    setcurrent(page);
  }

  const search1 = useLocation();

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const ListBidPoint = () => {
    const queryparams = new URLSearchParams(search1.search)
    const value = queryparams.get("changeStatus")
    setChangeStatus(value);
    RequestBidPointsService.RequestBidPoints(current, search, value ? value : 0)
      .then((data) => {
        setData(data.data);
        setLoader(true)
      })
      .catch((error) => {
        console.log("Errorreg", error);
        setLoader(false)
      });
  }


  useEffect(() => {
    ListBidPoint();
  }, [search, current]);


  const approveHandler = (_id,status) => {
    confirmAlert({
      title: t("Confirm"),
      message: t("BidPoint_Approve"),
      buttons: [
        {
          label: t("Yes"),
          onClick: () =>  ApproveBidPoint(_id,status)
        },
        {
          label: t("No"),
          onClick: () => null
        }
      ]
    });
  }


  const ApproveBidPoint = (_id,status) => {
    RequestBidPointsService.ApproveBidPoints(_id, status).then((data) => {
      if (data.data.status.code === 0) {
        dispatch(alertActions.success(data.data.status.message));
        setTimeout(
          () => (dispatch(alertActions.clear())),
          2000
        );
      }
      ListBidPoint();
      setStatus(data.data);

    })
      .catch((error) => {
        console.log("Errorreg", error);

      })
  }



  const rejectHandler = (_id,status) => {
    confirmAlert({
      title:t("Confirm"),
      message: t("BidPoint_Reject"),
      buttons: [
        {
          label: t("Yes"),
          onClick: () => RejectBidPoint(_id,status)
        },
        {
          label: t("No"),
          onClick: () => null
        }
      ]
    });
  }

  const RejectBidPoint = (_id,status) => {
    RequestBidPointsService.ApproveBidPoints(_id, status).then((data) => {
      if (data.data.status.code === 0) {
        dispatch(alertActions.success(data.data.status.message));
        setTimeout(
          () => (dispatch(alertActions.clear())),
          2000
        );
      }
      ListBidPoint();
      setStatus(data.data);
    })
      .catch((error) => {
        console.log("Errorreg", error);
      })
  }


  const setStateHandler = (val) => {
    setLoader(!loader);
    if (val === 0) {
      setChangeStatus(0)
    }
    else if (val === 1) {
      setChangeStatus(1)
    }
    else {
      setChangeStatus(2)
    }
    RequestBidPointsService.RequestBidPoints(current, search, val)
      .then((data) => {
        setLoader(true)
        setcurrent(1);
        setData(data.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        setLoader(false);
      });
  }


  const searchHandler = useCallback(
    (e) => {
      setSearch(e.target.value);
    }
    ,
    [search, setSearch],
  )


  return {
    data,
    current,
    status,
    show,
    changeStatus,
    loader,
    approveHandler,
    setChangeStatus,
    changeCurrentPage,
    searchHandler,
    rejectHandler,
    setStateHandler,
  };
}


export default useRequestBidPointsHooks