import * as axios from "../network/Network";

const config = require("../network/api.json");

class NewsService {
  static News(search, page) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.News + `?page=${page}&search=${search}`
    });
    return Promise.resolve(result)
  }

  static AddNewsUsers(news_title, news_dsc, news_img, status) {
    //console.log(news_title,news_dsc,news_img)
    var bodyFormdata = new FormData();
    bodyFormdata.append("news_title", news_title);
    bodyFormdata.append("news_dsc", news_dsc)
    bodyFormdata.append("news_img", news_img)
    bodyFormdata.append("status", status)
    return axios.appRequest({
      method: axios.POST,
      url: config.user.addNewsusers,
      data: bodyFormdata,
    });
  }

  static EditNewsUsers(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.editNewsusers + `/${id}`,
    });
    return Promise.resolve(result)
  }

  static NewsUsersView(id) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.ViewNewsUsers + `/${id}`,
    });
    return Promise.resolve(result)
  }

  static NewsUpdate(id, news_title, news_dsc, news_img, status) {
    var bodyFormData = new FormData();
    bodyFormData.append("news_title", news_title);
    bodyFormData.append("news_dsc", news_dsc);
    if (news_img && news_img.type) {
      bodyFormData.append("news_img", news_img);
    } else {
      bodyFormData.append("news_img", JSON.stringify(news_img));
    }
    bodyFormData.append("status", status);
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.updateNewsusers + `/${id}`,
      data: bodyFormData
    });
    return Promise.resolve(result);
  }

  static DeleteNewsUsers(id) {
    console.log(id)
    const result = axios.appRequest({
      method: axios.DELETE,
      url: config.user.deleteNewsusers + `/${id}`
    });
    return Promise.resolve(result)
  }

  static StatusCheck(id, status) {
    console.log(id);
    console.log(status);
    const result = axios.appRequest({
      method: axios.PUT,
      url: config.user.status_check + `/${id}`,
      data: { status }
    });
    return Promise.resolve(result);
  }
}

export { NewsService }