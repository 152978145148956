import React, { useEffect,useState } from "react";
import { useParams, useNavigate} from "react-router-dom";
import { ViewNewsUsersService } from "../../../services/ViewNewsUsersService";

function useViewNewsUsersHook(t){
    const params=useParams()
    let id=params.news_id;
    const navigate = useNavigate();
    const [data,setData]=useState("")
    const [loading,setLoading]=useState(false)
    const media_url = process.env.REACT_APP_MEDIA_DOMAIN;
    const [loader,setLoader]=useState(false)

    useEffect(()=>{
        setTimeout(() => {
        }, 1000);
        ViewNewsUsersService.viewNewsUsers(id)
        .then((data)=>{
            setData(data.data)
            setLoader(true)
        })
        .catch((error)=>{
            console.log("Error",error)
            setLoader(false)
        })
    },[])
    
    return{
        data,
        media_url,
        loader,
        navigate
    }
}

export default useViewNewsUsersHook