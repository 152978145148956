import React from 'react'
import { useState, useEffect, useCallback} from 'react'
import { BasicUsersService } from '../../../services/BasicUsersService';

function useBasicUsersHook(t) 
{
    const [data, setData] = useState("");
    const [current, setcurrent] = useState(1);
    const [search, setSearch] = useState("");
    const [changeStatus, setChangeStatus] = useState(0);
    const [loader,setLoader]=useState(false)

    const changeCurrentPage = (page) => {
        setcurrent(page);
    }
    
   
    const searchHandler = useCallback(
        (e) => {
          setSearch(e.target.value);
          setcurrent("");
        },
        [search, setSearch],
      )
    

    useEffect(() => {
        BasicUsersService.BasicUsers(search, changeStatus, current)
            .then((data) => {
                setData(data.data);
                setLoader(true)
            })
            .catch((error) => {
                console.log("Errorreg", error);
                setLoader(false)
            });
    }, [search, current]);


    return {
        data,
        current,
        changeStatus,
        loader,
        changeCurrentPage,
        searchHandler
    };
}
export default useBasicUsersHook