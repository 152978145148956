import React from 'react';
import './FavoriteFashionList.css'
import Header from '../../common/header/header'
import FooterContent from '../../common/footer/footer'
import { withTranslation } from "react-i18next";
import useFavoriteFashionListHooks from './useFavoriteFashionListHooks';
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import { Loading } from "../../../assests/images/images"


const FavoriteFashionList = (props) => {
    const { t } = props;
    const { data,
        current,
        changeCurrentPage,
        searchHandler,
        deleteHandler,
        changeHandler,
        changeFileHandler,
        handleSubmit,
        editHandler,
        submitEdit,
        handleCancel,
        add,
        loader,
        type,
        ref,
        validFile,
        selectedFile,
        imagePreviewUrl,
        isOpened,
        submitted,
        media_url,
        isNameValid
    } = useFavoriteFashionListHooks(props);




    const TableContents = () => {
        return (
            <div className="stylish_table flt">
                {!loader && <div className="load flt">
                    <img src={Loading} alt="Stylish" />
                </div>}
                <div className="stylish_top mb-4 flt">
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="page_head flt">{t("Fav_Fashion")}</div>
                        </div>
                        <div className="col-sm-4">
                            <div className="search_bar flt">
                                <input type="seach" placeholder="Search for name" onKeyUp={(e) => searchHandler(e)} className="form-control flt" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fashion_main flt">
                    <div className="row">
                        <div className="col-sm-3">
                            <form className="fashion_left flt">
                                <div className="form-group flt">
                                    <label>{t("Name")}</label>
                                    <input type="text" name="name" value={add.name} onChange={changeHandler} className={'form-control flt' + (submitted && !add.name.trim() ? ' is-invalid' : '')} />
                                    {submitted && !add.name.trim() &&
                                        <div className="invalid-feedback">{t("Name_Required")}</div>
                                    }
                                    {!isNameValid() && add.name.trim() &&
                                        <div className="invalid-feedback">{t("Valid_Fashion")}</div>
                                    }

                                </div>
                                <div className="form-group flt">
                                    <label>{t("Category")}</label>
                                    <select name="type" value={type} onChange={changeHandler} className={'form-control flt' + (submitted && !add.type.trim() ? ' is-invalid' : '')}>
                                        <option value='' key='' selected disabled>{t("Please_Select")}</option>
                                        <option value="M">{t("Mens")}</option>
                                        <option value="F">{t("Womens")}</option>
                                    </select>
                                    {submitted && !add.type.trim() &&
                                        <div className="invalid-feedback">{t("Type_is_required")}</div>
                                    }
                                </div>
                                <div className="form-group flt">
                                    <label>{t("Image")}</label>

                                    <button className="upload_btn"><i class="fas fa-file-upload"> <span> Upload file</span></i>
                                    <input
                                    type="file" 
                                    accept="image/*" 
                                    name="fashion_img" 
                                    onChange={changeFileHandler} 
                                    ref={ref}
                                     className={'form-control flt' + (submitted && !imagePreviewUrl ? ' is-invalid' : '')} />
                                    </button>
                                    
                                    {submitted && !selectedFile &&
                                        <div className="invalid-feedback">{t("Image_required")}</div>
                                    }
                                    {submitted && selectedFile && !validFile &&
                                        <div className="invalid-feedback">{t("Valid_image")}</div>
                                    }
                                    <div className="favourite_preview flt">
                                        {imagePreviewUrl &&
                                            <img src={imagePreviewUrl} />
                                        }
                                    </div>
                                </div>
                                <div className="fashion_submit flt">
                                    {!isOpened && <button onClick={handleSubmit}>{t("Add")}</button>}
                                    {isOpened && <button onClick={submitEdit}>{t("Update")}</button>}
                                    {isOpened && <button onClick={handleCancel}>{t("Cancel")}</button>}
                                </div>
                            </form>
                        </div>
                        <div className="col-sm-9">
                            <div className="fashion_right flt">
                                {isOpened && <div className="table_overlay"></div>}
                                <div className="table-responsive">
                                    <table className="table convoc_table">
                                        <thead>
                                            <tr>
                                                <th width="100px">{t("Sl_No")}</th>
                                                <th>{t("Name")}</th>
                                                <th>{t("Image")}</th>
                                                <th>{t("Category")}</th>
                                                <th width="120px">{t("Action")}</th>
                                                <th width="80px">{t("View")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {data && data.data && data.data.docs.length ? data.data.docs.map((list, index) => (
                                                <tr key={list._id}>
                                                    <td>{20 * (data.data.page - 1) + index + 1}.</td>
                                                    <td dangerouslySetInnerHTML={{ __html: list?.name}}></td>
                                                    <td><img src={media_url + list?.fashion_img} className="fashion_image" alt="image" /></td>
                                                    <td>{list?.type == "F" ? "Womens" : list?.type == "M" ? "Mens" : ""}</td>
                                                    <td>
                                                        <button className='edit' onClick={() => editHandler(list._id)}><i className="fas fa-pencil-alt"></i></button>
                                                        <button className='delete' onClick={() => deleteHandler(list._id)}><i className="fas fa-trash-alt"></i> </button>
                                                    </td>

                                                    <td><Link to={"/favorite-fashion-view/" + list._id} className='view'><i class="fas fa-eye"></i></Link></td>
                                                </tr>
                                            )) : <tr><td colSpan={6}><div className='no_data flt' >No data</div></td></tr> 
                                            }
                                        </tbody>
                                    </table>
                                </div>
                           
                                <div className="row showrec">
                                    <div className="col-sm-6">
                                        {data && data.data &&
                                            <div className="ShowRec flt"> Showing {current} to {data.data.limit} of {data.data.docs.length} entries</div>}

                                    </div>
                                    <div className="col-sm-6">
                                        <div className="pagination_container flt">
                                            {data && data.data &&
                                                <Pagination
                                                    activePage={current}
                                                    itemsCountPerPage={20}
                                                    totalItemsCount={data.data && data.data.totalPages * data.data.limit}
                                                    pageRangeDisplayed={5}
                                                    onChange={changeCurrentPage}
                                                    firstPageText="First"
                                                    lastPageText="Last"
                                                    prevPageText="Previous"
                                                    nextPageText="Next"
                                                />

                                            }

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <>
            <div className="main_container flt ">
                {Header()}
                <div className="page_container flt">
                    {TableContents()}
                </div>
                {FooterContent()}
            </div>
        </>
    )
}

export default withTranslation()(FavoriteFashionList);
