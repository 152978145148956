
import React, { useEffect, useState } from "react";
import './StylistBlog.css'
import { logo } from '../../../assests/images/images'
import Header from '../../common/header/header'
import FooterContent from '../../common/footer/footer'
import { withTranslation } from "react-i18next";
import useStylistBlogHook from './useStylistBlogHook';
import { Default } from '../../../assests/images/images';
import Pagination from "react-js-pagination";
import { Link, useLocation } from "react-router-dom";
import { StylistBlogService } from '../../../services/StylistBlogService';
import { Loading } from "../../../assests/images/images"

const StylistBlog = (props) => {
    const { t } = props;
    const { data,
        current,
        media_url,
        loader,
        changeCurrentPage,
        searchHandler,
        deleteHandler,
    } = useStylistBlogHook(t);

    const TableContents = () => {
        return (
            <div className="stylish_table flt">
                {!loader && <div className="load flt">
                    <img src={Loading} alt="Stylish" />
                </div>}
                <div className="stylish_top flt">
                    <div className="page_head flt mb-4">{t("Stylist_Blog")}</div>
                    <div className="row">
                        <div className="col-sm-8">
                        </div>
                        <div className="col-sm-4">
                            <div className="search_bar flt">
                                <input type="seach" placeholder="Search for stylist user or blog title" onKeyUp={(e) => searchHandler(e)} className="form-control flt" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table-responsive">
                    <table className="table convoc_table">
                        <thead>
                            <tr>
                                <th width="100px">{t("Sl_No")}</th>
                                <th>{t("Stylist_User")}</th>
                                <th>{t("Email")}</th>
                                <th>{t("Blog_Title")}</th>
                                <th>{t("Blog_Description")}</th>
                                <th>{t("Blog_Image")}</th>
                                <th>{t("Action")}</th>
                                <th>{t("Total_Views")}</th>
                                <th width="80px">{t("View")}</th>
                            </tr>
                        </thead>
                        <tbody>

                            {data && data.data && data.data.docs.length ? data.data.docs.map((list, index) => (
                                <tr key={list._id}>
                                    <td>{10 * (data.data.page - 1) + index + 1}.</td>
                                    <td> {list?.user.full_name}</td>
                                    <td> {list?.user.email}</td>
                                    <td><div className="news_title">{list?.blog_title}</div></td>
                                    <td><div className="news_title">{list?.blog_dsc}</div></td>
                                    <td> {
                                        list.blog_img ?
                                            <img src={media_url + list.blog_img} className="sdefault_img" />
                                            : <img src={Default} className="sdefault_img" />
                                    }</td>

                                    <td>
                                        <button className='reject' onClick={() => deleteHandler(list._id)}><i className="fas fa-trash-alt"></i> </button>
                                    </td>
                                    <td>{list?.total_views}</td>
                                    <td><Link to={"/blog-view/" + list._id} className='view'><i className="fas fa-eye"></i></Link></td>
                                </tr>
                            )) : <tr><td colSpan={9}><div className='no_data flt' >No data</div></td></tr> 
                            }
                        </tbody>
                    </table>
                </div>
                <div className="row showrec">
                    <div className="col-sm-6">
                        <div> {data && data.data &&
                            <div> Showing {current} to {data.data.limit} of {data.data.docs.length} entries</div>}
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="pagination_container flt">
                            {data && data.data &&
                                <Pagination
                                    activePage={current}
                                    itemsCountPerPage={10}
                                    totalItemsCount={data.data && data.data.totalPages * data.data.limit}
                                    pageRangeDisplayed={5}
                                    onChange={changeCurrentPage}
                                    firstPageText="First"
                                    lastPageText="Last"
                                    prevPageText="Previous"
                                    nextPageText="Next"
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="main_container flt ">
                {Header()}
                <div className="page_container flt">
                    {TableContents()}
                </div>
                {FooterContent()}
            </div>
        </>
    )
}

export default withTranslation()(StylistBlog)
