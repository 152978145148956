import React from 'react';
import Header from '../../common/header/header';
import FooterContent from '../../common/footer/footer';
import { withTranslation } from "react-i18next";
import './ViewWithdrawals.css';
import useViewWithdrawalHooks from './useViewWithdrawalHooks';
import { Loading } from "../../../assests/images/images";


function ViewWithdrawals(props) {
    const { t } = props;
    const { data, loader, navigate } = useViewWithdrawalHooks(t);
 
    const ViewWithdrawalContent = () => {
        return (
            <>
               <div className='Card-container flt'>
                    {!loader && <div className="load flt">
                        <img src={Loading} alt="Stylish" />
                    </div>}
                    <div class="page_head flt mb-4">{t("Withdrawal_Details")}
                    </div>
                    <div className='back_button1 flt'>
                        <button onClick={() => { navigate(-1) }}> <i class="fas fa-arrow-left"></i> {t("Back")}</button>
                    </div>
                    {data && data.data && data.data.data &&
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className="styuser_list flt" >
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                        <ul className='styldetails3 flt'>
                                            
                                                <li><b>{t("Withdraw_Point")}: </b> <span>{data.data.data.withdrawPoint}</span></li>
                                                <li><b>{t("Withdraw_Status")}: </b><span>{data.data.data.withdrawStatus == 0 ? "pending" : data.data.data.withdrawStatus == 1 ? "processed" : ""}</span></li>
                                                <li><b>{t("Full_Name")}: </b><span>{data.data.data.fullName}</span></li>
                                                <li><b>{t("Email")}: </b> <span>{data.data.data.email}</span></li>
                                            </ul>
                                       </div>
                                        {/* <div className='col-sm-4'>
                                        <ul className='styldetails3 flt'>
                                                <li className='styhead flt'>{t("Bank_Details")}</li>
                                                <li> <b>{t("First_Name")}: </b>{data.data.data.bank_detail.first_name}</li>
                                                <li> <b>{t("Sur_Name")}: </b>{data.data.data.bank_detail.sur_name}</li>
                                                <li> <b>{t("Bank_Name")}: </b>{data.data.data.bank_detail.bank_name}</li>
                                                <li><b>{t("Branch_Name")}: </b>{data.data.data.bank_detail.brnach_name}</li>
                                                <li><b>{t("Account_Details")}: </b>{data.data.data.bank_detail.account_type}</li>
                                                <li><b>{t("Account_Number")}: </b>{data.data.data.bank_detail.account_number}</li>
                                            </ul> 
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }



    return (
        <>
            {Header()}
            <div className="page_container flt">
                {ViewWithdrawalContent()}
            </div>
            {FooterContent()}
        </>
    )
}

export default withTranslation()(ViewWithdrawals)