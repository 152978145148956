import React from 'react';
import './FavoriteBrandList.css'
import { logo } from '../../../assests/images/images'
import Header from '../../common/header/header'
import FooterContent from '../../common/footer/footer'
import { withTranslation } from "react-i18next";
import useFavoriteBrandListHooks from './useFavoriteBrandListHooks';

import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import { FavoriteBrandListService } from '../../../services/FavoriteBrandListService';
import { Loading } from "../../../assests/images/images"

const FavoriteBrandList = (props) => {
    const { t } = props;
    const { data,
        current,
        editHandler,
        changeCurrentPage,
        searchHandler,
        deleteHandler,
        changeHandler,
        handleSubmit,
        updateHandler,
        cancelHandler,
        submitted,
        ref,
        loader,
        name,
        status,
        isOpened,
        isNameValid
    } = useFavoriteBrandListHooks(props);

    const TableContents = () => {
        return (
            <div className="stylish_table flt">
                {!loader && <div className="load flt">
                    <img src={Loading} alt="Stylish" />
                </div>}
                <div className="stylish_top mb-4 flt">
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="page_head flt">{t("Fav_Brand_List")}</div>
                        </div>
                        <div className="col-sm-4">
                            <div className="search_bar flt">
                                <input type="seach" placeholder="Search for name" onKeyUp={(e) => searchHandler(e)} className="form-control flt" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fashion_main flt">
                    <div className="row">
                        <div className="col-sm-3">
                            <form className="fashion_left flt">
                                <div className="form-group flt">
                                    <label>{t("Name")}</label>
                                    <input type="text" ref={ref} name="name" value={name} onChange={changeHandler} className={'form-control flt' + (submitted && !name.trim() ? ' is-invalid' : '')} />
                                    {submitted && !name.trim() &&
                                        <div className="invalid-feedback">{t("Name_Required")}</div>
                                    }
                                    {/* {!isNameValid() && name &&
                                        <div className="invalid-feedback">{t("Valid_Brand")}</div>
                                    } */}

                                </div>
                                {isOpened &&
                                    <div className="form-group flt">
                                        <label>{t("Status")}</label>
                                        <select name="status" value={status} onChange={changeHandler} className="form-control flt">
                                            <option value='' key='' selected disabled>{t("Please_Select")}</option>
                                            <option value="1">{t("Active")}</option>
                                            <option value="0">{t("In_Active")}</option>
                                        </select>
                                        {submitted && !status.trim() &&
                                            <div className="invalid-feedback">{t("Enter_Status")}</div>
                                        }

                                    </div>
                                }
                                <div className="fashion_submit flt">
                                    {!isOpened && <button onClick={handleSubmit}>{t("Add")}</button>}
                                    {isOpened && <button onClick={updateHandler}>{t("Update")}</button>}
                                    {isOpened && <button onClick={cancelHandler}>{t("Cancel")}</button>}
                                </div>
                            </form>
                        </div>
                        <div className="col-sm-9">
                            <div className="fashion_right flt">
                                {isOpened && <div className='table_overlay'></div>}
                                <div className="table-responsive">
                                    <table className="table convoc_table">
                                        <thead>
                                            <tr>
                                                <th width="100px">{t("Sl_No")}</th>
                                                <th>{t("Name")}</th>
                                                <th>{t("Status")}</th>
                                                <th width="120px">{t("Action")}</th>
                                                <th width="80px">{t("View")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.data && data.data.length ? data.data.map((list, index) => (
                                                <tr key={list._id}>
                                                    <td>{index + 1}.</td>
                                                    <td dangerouslySetInnerHTML={{ __html: list?.name}}></td>
                                                    <td>{list?.status == 0 ? "Inactive" : "Active"}</td>
                                                    <td>
                                                        <button className='edit' onClick={() => editHandler(list._id, list.name, list.status)}><i className="fas fa-pencil-alt"></i> </button>
                                                        <button className='reject' onClick={() => deleteHandler(list._id, 1)}><i class="fas fa-trash-alt"></i> </button>
                                                    </td>
                                                    <td><Link to={"/favorite-brandview/" + list._id} className='view'><i class="fas fa-eye"></i></Link></td>
                                                </tr>
                                            )) : <tr><td colSpan={5}><div className='no_data flt' >No data</div></td></tr> 
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <>
            <div className="main_container flt ">
                {Header()}
                <div className="page_container flt">
                    {/* {DashItems()} */}
                    {TableContents()}
                </div>
                {FooterContent()}
            </div>
        </>
    )
}

export default withTranslation()(FavoriteBrandList);
