import React from 'react';
import { logo } from '../../../assests/images/images';
import { Default } from '../../../assests/images/images';
import Header from '../../common/header/header';
import FooterContent from '../../common/footer/footer';
import { withTranslation } from "react-i18next";
import './FAQheader.css';
import Pagination from "react-js-pagination";
import useFAQHeaderHook from './useFAQHeaderHook';
import { FAQheaderService } from '../../../services/FAQheaderService';
import { Link } from "react-router-dom";
import { Loading } from "../../../assests/images/images";


const FAQHeader = (props) => {
    const { t } = props;
    const { data,
        current,
        loader,
        status,
        header,
        validFile,
        searchHandler,
        selectedFile,
        submitted,
        ref,
        changeHandler,
        deleteHandler,
        media_url,
        changeFileHandler,
        imagePreviewUrl,
        isOpened,
        handleSubmit,
        editHandler,
        submitEdit,
        handleCancel,
        isNameValid
    } = useFAQHeaderHook(props);


    const FAQHeaderContent = () => {
        return (
            <>
                <div className="stylish_table flt">
                    {!loader && <div className="load flt">
                        <img src={Loading} alt="Stylish" />
                    </div>}
                    <div className="stylish_top mb-4 flt">
                        <div className="row">
                            <div className="col-sm-8">
                                <div className="page_head flt">{t("FAQHeader")}</div>
                            </div>
                            <div className="col-sm-4">
                                <div className="search_bar flt">
                                    <input type="seach" placeholder="Search for FAQs" onKeyUp={(e) => searchHandler(e)} className="form-control flt" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fashion_main flt">
                        <div className="row">
                            <div className="col-sm-3">
                                <form className="fashion_left flt">
                                    <div className="form-group flt">
                                        <label>{t("FAQ_header")}</label>
                                        <input type="text" name="faq_header" value={header} onChange={changeHandler} className={'form-control flt' + (submitted && !header.trim() ? ' is-invalid' : '')} />
                                        {submitted && !header &&
                                            <div className="invalid-feedback">{t("Header_required")}</div>
                                        }
                                        {!isNameValid() && header &&
                                            <div className="invalid-feedback">{t("Valid_Header")}</div>
                                        }
                                    </div>

                                    <div className="form-group flt">

                                        <label>{t("FAQ_image")}</label>
                                        <button className="upload_btn"><i class="fas fa-file-upload"> <span>Upload file</span> </i>
                                            <input type="file" accept="image/*" name="faq_img" onChange={changeFileHandler} ref={ref} className={'form-control flt' + (submitted && !imagePreviewUrl ? ' is-invalid' : '')} />
                                        </button>
                                        {submitted && !selectedFile &&
                                            <div className="invalid-feedback">{t("Image_required")}</div>
                                        }
                                        {submitted && selectedFile && !validFile &&
                                            <div className="invalid-feedback">{t("Valid_image")}</div>
                                        }
                                        <div className="favourite_preview flt">

                                            {imagePreviewUrl &&
                                                <img src={imagePreviewUrl} />
                                            }

                                        </div>
                                    </div>

                                    <div className="fashion_submit flt">
                                        {!isOpened && <button onClick={handleSubmit}>{t("Add")}</button>}
                                        {isOpened && <button onClick={submitEdit}>{t("Update")}</button>}
                                        {isOpened && <button onClick={handleCancel}>{t("Cancel")}</button>}
                                    </div>
                                </form>
                            </div>
                            <div className="col-sm-9">
                                <div className="fashion_right flt">
                                    {isOpened && <div className="table_overlay"></div>}
                                    <div className="table-responsive">
                                        <table className="table convoc_table">
                                            <thead>
                                                <tr>
                                                    <th width="100px">{t("Sl_No")}</th>
                                                    <th>{t("FAQ_header")}</th>
                                                    <th>{t("FAQ_image")}</th>
                                                    <th>{t("Action")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data && data.data && data.data.data && data.data.data.length ? data.data.data.map((list, index) => (
                                                    <tr key={list._id}>
                                                        <td>{index + 1}.</td>

                                                        <td dangerouslySetInnerHTML={{ __html: list?.faq_header }}></td>
                                                        <td> {
                                                            list.faq_img ?
                                                                <img src={media_url + list.faq_img} className="faqdefault_img" />
                                                                : <img src={Default} className="faqdefault_img" />
                                                        }</td>
                                                        <td>
                                                            <button className='edit' onClick={() => editHandler(list._id)}><i className="fas fa-pencil-alt"></i> </button>
                                                            <button className='delete' onClick={() => deleteHandler(list._id)}><i className="fas fa-trash-alt"></i> </button>
                                                        </td>
                                                    </tr>
                                                )) :  
                                                
                                                       <tr><td colSpan={4}><div className='no_data flt' >No data</div></td></tr> 
                                                       
                                                    
                                                
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return (
        <>
            {Header()}
            <div className="page_container flt">
                {FAQHeaderContent()}
            </div>
            {FooterContent()}
        </>
    )
}

export default withTranslation()(FAQHeader);