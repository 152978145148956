import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { ListWithdrawalService } from '../../../services/ListWithdrawalService'
import { alertActions } from "../../../actions/alert.actions";
import { useDispatch } from 'react-redux';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useLocation } from 'react-router-dom';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { withTranslation } from "react-i18next";

const useListWithdrawalHooks=(props)=> {
  const { t } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(0);
  const [current, setcurrent] = useState(1);
  const [withdrawStatus, setwithdrawStatus] = useState(0);
  const [loader, setLoader] = useState(false);

  /** Status update */
  const setStateHandler = (val) => {
    setLoader(!loader);
    if (val === 0) {
      setwithdrawStatus(0)
    }
    else if (val === 1) {
      setwithdrawStatus(1)
    }
    else {
      setwithdrawStatus(2)
    }
    ListWithdrawalService.ListWithdrawal(val, search)
      .then((data) => {
        setLoader(true)
        setcurrent(1);
        setData(data.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        setLoader(false)
      });
  }


  /** Pagination */
  const changeCurrentPage = (page) => {
    setcurrent(page);
  }


  useEffect(() => {
    ListWithdrawal();
  }, [current, search])


  /** Search data */

  const searchHandler = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [search, setSearch],
  )



  const search1 = useLocation();
  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }



  /**  render method to avoid page reload*/
  const ListWithdrawal = () => {
    const queryparams = new URLSearchParams(search1.search)
    const value = queryparams.get("withdrawStatus")
    setwithdrawStatus(value);
    ListWithdrawalService.ListWithdrawal(value ? value : 0, search, current)
      .then((data) => {
        setLoader(true)
        setData(data.data);
        console.log(data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        setLoader(false)
      });
  }



  const approveHandler = (_id, status) => {
    console.log(status)
    confirmAlert({
      title: t("Confirm"),
      message: t("Withdrawal_Approve"),
      buttons: [
        {
          label:t("Yes"),
          onClick: () => ApproveWithdrawal(_id,status)
        },
        {
          label:t("No"),
          onClick: () => null
        }
      ]
    });
  }


  const ApproveWithdrawal=(_id,status)=>{
    ListWithdrawalService.WithdrawalUpdate(_id, status).then((data) => {
      if (data.data.status.code === 0) {
        dispatch(alertActions.success(data.data.status.message));
        setTimeout(
          () => (dispatch(alertActions.clear())),
          2000
        );
      }
      setStatus(data.data);
      ListWithdrawal();

    })
      .catch((error) => {
        console.log("Errorreg", error);
      })
  }

  const rejectHandler = (_id, status) => {
    confirmAlert({
      title: t("Confirm"),
      message: t("Withdrawal_Reject"),
      buttons: [
        {
          label: t("Yes"),
          onClick: () => RejectWithdrawal(_id,status)
        },
        {
          label: t("No"),
          onClick: () => null
        }
      ]
    });
  }


const RejectWithdrawal=(_id,status)=>{
  ListWithdrawalService.WithdrawalUpdate(_id, status).then((data) => {
    if(data.data.status.code === 0)
    {
      dispatch(alertActions.success(data.data.status.message));
        setTimeout(
          () => (dispatch(alertActions.clear())),
          2000
        );
    }
    setStatus(data.data);
    ListWithdrawal();
  })
  .catch((error) => {
    console.log("Errorreg", error);
  })
}

  return {
    data,
    current,
    status,
    loader,
    withdrawStatus,
    approveHandler,
    rejectHandler,
    setwithdrawStatus,
    changeCurrentPage,
    searchHandler,
    setStateHandler
  }
}

export default useListWithdrawalHooks;