import * as axios from "../network/Network";

const config = require("../network/api.json");

class LinkService 
{
  static LinkList() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.LinkList
    });
    return Promise.resolve(result);
  }
 
/**
 * Add FAQ
 */
static LinkAdd(link) {
  
  var bodyFormData = new FormData();
  bodyFormData.append("link", link);
  const result = axios.appRequest({
    method: axios.POST,
    url: config.user.LinkAdd,
    data: bodyFormData,
  });
  console.log(result.url)
  return Promise.resolve(result);
}




/**
 * FAQ View & Edit api
 */
static LinkView(id) {
  const result = axios.appRequest({
    method: axios.GET,
    url: config.user.LinkView+`/${id}`
  });
  console.log(result.url);
  return Promise.resolve(result);
}

/**
 * Update FAQ
 */
static LinkUpdate(link,id,status) {
  console.log(status,"link,id")
  var bodyFormData = new FormData();
  bodyFormData.append("link", link);
  bodyFormData.append("status", status);
  const result = axios.appRequest({
    method: axios.PUT,
    url: config.user.LinkUpdate+`/${id}`,
    data: bodyFormData,
  });
  return Promise.resolve(result);
}


 static LinkDelete(id) {
  const result = axios.appRequest({
    method: axios.DELETE,
    url: config.user.LinkDelete+`/${id}`
  });
  return Promise.resolve(result);
}


}
export { LinkService };
