import React from "react";
import useNewsHooks from "./useNewsHook";
import { Link } from "react-router-dom";
import Header from "../../common/header/header";
import FooterContent from "../../common/footer/footer";
import './News.css';
import "react-image-crop/dist/ReactCrop.css";
import Pagination from "react-js-pagination";
import { Loading } from "../../../assests/images/images"
import { withTranslation } from "react-i18next";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';


const News = (props) => {
    const { t } = props
    const {
        data,
        media_url,
        searchHandler,
        current,
        changeCurrentPage,
        selectFile,
        deleteHandler,
        handleSubmit,
        changeHandler,
        handleFileChange,
        title,
        loader,
        dsc,
        img,
        fileName,
        changeImageHandler,
        editNewsHandler,
        isOpened,
        imagePreviewUrl,
        ref,
        show,
        validFile,
        limitFile,
        submitEdit,
        handleCancel,
        status,
        selectedFile,
        submitted,
        loading,
        cropImageNow,
        src,
        output,
        setImage,
        setImagePreviewUrl,
        crop,
        setCrop,
        selectImage,
        srcImg,
        result,
        displayCrop,
        editCrop,
        getCroppedImg,

    } = useNewsHooks(props)

    const newsstyle = {
        width: "100%",
        resize: "none",
        border: "1px solid #ced4da",

    }

    const Table = () => {
        return (
            <>
                <div className="stylish_table flt">
                    {!loader && <div className="load flt">
                        <img src={Loading} alt="Stylish" />
                    </div>}

                    <div className="stylish_top flt">
                        <div className="row">
                            <div className="page_head flt mb-2">{t("News")}</div>
                        </div>
                    </div>

                    <div className="newsform flt">
                        <div className="row">
                            <div className="col-sm-8">
                                <div>
                                    <form >
                                        <div className="form-group flt" >
                                            <label><b>{t("Add_Title")}:</b></label>
                                            <input type="text" className={'form-control flt' + (submitted && !title ? ' is-invalid' : '')} name="title" value={title} onChange={changeHandler} />
                                            {submitted && !title &&
                                                <div className="invalid-feedback">{t("Title_required")}</div>
                                            }
                                        </div>
                                        <div className="form-group flt">
                                            <label><b>{t("Add_Description")}:</b></label>
                                            <textarea style={newsstyle} className={'form-control flt' + (submitted && !dsc ? ' is-invalid' : '')} name="dsc" value={dsc} onChange={changeHandler}></textarea>
                                            {submitted && !dsc &&
                                                <div className="invalid-feedback">{t("Description_Required")}</div>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div>
                                    {isOpened &&
                                        <div className="form-group flt">
                                            <label> <b>{t("Status")}: </b></label><br />
                                            <select className='form-control' name="status" value={status} onChange={changeHandler} >
                                                <option value='' key='' selected disabled>{t("Please_Select")}</option>
                                                <option value="1">{t("Active")}</option>
                                                <option value="0">{t("In_Active")}</option>
                                            </select>
                                        </div>
                                    }
                                    <div className="form-group flt">
                                        <label><b>{t("Upload_Image")} :</b></label><br></br>
                                        <button className="upload_btn"><i class="fas fa-file-upload">  <span>Upload file</span></i>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={changeImageHandler}
                                                name="news_img"
                                                ref={ref}
                                                className={'form-control flt' + (submitted && !imagePreviewUrl ? ' is-invalid' : '')}/> </button>
                                        {/* <div className="crop_box flt"> */}
                                        {/* {srcImg && (
                                                <div className="crop_image">
                                                    {!editCrop && <ReactCrop
                                                        src={srcImg}
                                                        onImageLoaded={setImage}
                                                        crop={crop}
                                                        onChange={setCrop}
                                                    />
                                                    }
                                                    {editCrop && <img
                                                        src={srcImg} />
                                                    }
                                                </div>
                                            )} */}
                                        {/* {displayCrop && !editCrop && (
                                                <div className="crop_image">
                                                    <img src={displayCrop} alt="cropped image" />
                                                </div>
                                            )} */}
                                        {/* </div> */}
                                        {/* {srcImg && !editCrop && (
                                            <div className="crop_btn flt">
                                                <button onClick={getCroppedImg}>
                                                    {t("Crop_Image")}
                                                </button>
                                            </div>
                                        )} */}
                                        {/* {submitted && !result &&
                                            <div className="invalid-feedback">{t("Image_required")}</div>
                                        }
                                        {submitted && result && !validFile &&
                                            <div className="invalid-feedback">{t("Valid_image")}</div>
                                        } */}
                                        {submitted && !selectedFile &&
                                            <div className="invalid-feedback">{t("Image_required")}</div>
                                        }
                                        {submitted && selectedFile && !validFile &&
                                            <div className="invalid-feedback">{t("Valid_image")}</div>
                                        }
                                        <div className="favourite_preview flt">
                                            {imagePreviewUrl &&
                                                <img src={imagePreviewUrl} />
                                            }
                                        </div>
                                    </div>

                                </div>
                                <div className="news_submit flt">
                                    {!isOpened && <button onClick={handleSubmit}>{t("Add")}</button>}
                                    {isOpened && <button onClick={submitEdit}>{t("Update")}</button>}
                                    {isOpened && <button onClick={handleCancel}>{t("Cancel")}</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                        </div>
                        <div className="col-sm-6">
                            <div className="search_bar1 flt">
                                <input type="search" placeholder="Search for title" className="form-control flt" onKeyUp={(e) => searchHandler(e)}></input>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="fashion_right flt">
                                <div className="table-responsive">
                                    <table className="table convoc_table">
                                        <thead>
                                            <tr>
                                                <th width="80px">{t("Sl_No")}</th>
                                                <th width="180px">{t("Title")}</th>
                                                <th>{t("Description")}</th>
                                                <th width="100px">{t("Image")}</th>
                                                <th width="100px">{t("Status")}</th>
                                                <th width="120px">{t("Action")}</th>
                                                <th>{t("Total_Views")}</th>
                                                <th width="80px">{t("View")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data && data.data && data.data.docs.length ?
                                                    data.data.docs.map((list, index) => (

                                                        <tr key={list._id}>
                                                            <td>{10 * (data.data.page - 1) + index + 1}.</td>
                                                            <td><div className="news_title" dangerouslySetInnerHTML={{ __html: list?.news_title }}></div></td>
                                                            <td><div className="news_desc" dangerouslySetInnerHTML={{ __html: list?.news_dsc }}></div></td>
                                                            <td><img src={media_url + list?.news_img} alt='...' className="fashion_image"></img></td>
                                                            <td>{list?.status === 1 ? "Active" : "Inactive"}</td>
                                                            <td>
                                                                <button className="edit" onClick={() => editNewsHandler(list._id)}>
                                                                    <i className="fas fa-pencil-alt"></i>
                                                                </button>
                                                                <button className="delete" onClick={() => deleteHandler(list._id)}>
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </td>
                                                            <td>{t("Stylist")}: {list?.total_views_stylist}<br />{t("Basic")}: {list?.total_views_user}</td>
                                                            <td><Link to={"/view-news/" + list._id} className='view'><i className="fas fa-eye"></i></Link></td>
                                                        </tr>
                                                    )) : <tr><td colSpan={7}><div className='no_data flt' >No data</div></td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row showrec">
                        <div className="col-sm-6">
                            {data && data.data &&
                                <div className="ShowRec flt"> Showing {current} to {data.data.limit} of {data.data.docs.length} entries</div>}
                        </div>
                        <div className="col-sm-6">
                            <div className="pagination_container flt">
                                {data && data.data &&
                                    <Pagination
                                        activePage={current}
                                        itemsCountPerPage={10}
                                        totalItemsCount={data.data && data.data.totalPages * data.data.limit}
                                        pageRangeDisplayed={5}
                                        onChange={changeCurrentPage}
                                        firstPageText="First"
                                        lastPageText="Last"
                                        prevPageText="Previous"
                                        nextPageText="Next"
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="main_container flt">
                {Header()}
                <div className="page_container flt">
                    {Table()}
                </div>
                {FooterContent()}
            </div>
        </>
    )
}

export default withTranslation()(News);