import * as axios from "../network/Network";

const config = require("../network/api.json");

class StylishUsersService 
{
  static StylishUsers(search,status,pno,FromDate,ToDate) {
 
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.stylishUsers+`?search=${search}&status=${status}&page=${pno}&from_date=${FromDate}&to_date=${ToDate}`
    });
    return Promise.resolve(result);
  }
/**
 * status check api
 */
 static StatusCheck(id, status) {
  console.log(id);
  console.log(status);
  const result = axios.appRequest({
    method: axios.PUT,
    url: config.user.status_check+`/${id}`,
    data:{status}
  });
  return Promise.resolve(result);
}
 
static StylistUsersCount(FromDate,ToDate) {
  const result = axios.appRequest({
    method: axios.GET,
    url: config.user.StylistUsersCount+`?from_date=${FromDate}&to_date=${ToDate}`
  });
  console.log(result.url);
  return Promise.resolve(result);
}

}
export { StylishUsersService };
