import * as axios from "../network/Network";

const config = require("../network/api.json");

class RequestBidPointsService 
{
  static RequestBidPoints(pno,search,status) {
    console.log(pno);
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.RequestBidPoints+`?page=${pno}&search=${search}&status=${status}`
    });
    console.log(result.url);
    return Promise.resolve(result);
  }
/**
 * status check api
 */
 static ApproveBidPoints(id, status) {
  console.log(id);
  console.log(status);
  const result = axios.appRequest({
    method: axios.PATCH,
    url: config.user.ApproveBidPoints+`/${id}`,
    data:{status}
  });
  console.log(result.url);
  return Promise.resolve(result);
}
 


}
export { RequestBidPointsService };
