import React from 'react';
import './BasicUsers.css';
import Header from '../../common/header/header'
import FooterContent from '../../common/footer/footer'
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useBasicUsersHook from './useBasicUsersHook';
import Pagination from "react-js-pagination";
import { Loading } from "../../../assests/images/images"

const BasicUsers = (props) => {
    const { t } = props;
    const { data, current, loader, changeStatus, changeCurrentPage, searchHandler } = useBasicUsersHook();

    const BasicUsersList = () => {
        return (
            <div className='stylish_table flt'>
                {!loader && <div className="load flt">
                    <img src={Loading} alt="Stylish" />
                </div>}
                <div className="stylish_top flt">
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="page_head flt">{t("Basic_User")}</div>
                        </div>
                        <div className="col-sm-4">
                            <div className="search_bar flt">
                                <input type="seach" placeholder="Search for name or email" onKeyUp={(e) => searchHandler(e)} className="form-control flt" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th width="100px">{t("Sl_No")}</th>
                                <th>{t("Name")}</th>
                                <th>{t("Email")}</th>
                                <th>{t("View")}</th>
                            </tr>
                        </thead>
                        <tbody>

                            {data && data.data && data.data.docs !== null ? data.data.docs.map((list, index) => (
                                <tr key={list._id}>
                                    <td>{20 * (data.data.page - 1) + index + 1}.</td>
                                    <td>{list?.name}</td>
                                    <td>{list?.email} </td>
                                    <td><Link to={"/view-basicusers/" + list._id} className='view'><i className="fas fa-eye"></i></Link></td>
                                </tr>
                            )) : <tr><td colSpan={4}><div className='no_data flt' >No data</div></td></tr> 
                            }
                        </tbody>
                    </table>
                </div>

                <div className="row showrec">
                    <div className="col-sm-6">
                        {data && data.data &&
                            <div className="ShowRec flt"> Showing {current} to {data.data.limit} of {data.data.docs.length} entries</div>}
                    </div>
                    <div className="col-sm-6">
                        <div className="pagination_container flt">
                            {data && data.data &&
                                <Pagination
                                    activePage={current}
                                    itemsCountPerPage={20}
                                    totalItemsCount={data.data && data.data.totalPages * data.data.limit}
                                    pageRangeDisplayed={5}
                                    onChange={changeCurrentPage}
                                    firstPageText="First"
                                    lastPageText="Last"
                                    prevPageText="Previous"
                                    nextPageText="Next"
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            {Header()}
            <div className="page_container flt">
                {BasicUsersList()}
            </div>
            {FooterContent()}
        </>

    )
}


export default withTranslation()(BasicUsers);