import React from 'react'
import { logo } from '../../../assests/images/images'
import { Default } from '../../../assests/images/images'
import Header from '../../common/header/header'
import FooterContent from '../../common/footer/footer'
import { withTranslation } from "react-i18next";
import './ViewBasicUsers.css';
import useViewBasicUsersHook from './useViewBasicUsersHook'
import { ViewUserService } from '../../../services/ViewUserService';
import { Link, useNavigate } from "react-router-dom";
import { Loading } from "../../../assests/images/images"
import { format } from 'date-fns';
import moment from "moment";

function ViewBasicUsers(props) {
    const { t } = props;
    const { image_url, data, navigate, videoFile, loader, PageHandler, setStateHandler, changeStatus } = useViewBasicUsersHook(t);

    const ViewUserContent = () => {
        return (
            <>
                <div className='viewuser_container flt'>
                    {!loader && <div className="load flt">
                        <img src={Loading} alt="Stylish" />
                    </div>}
                    <div className='page_head flt mb-4'>{t("BasicUser_Details")}</div>
                    <div className='back_button1 flt'>
                        <button onClick={() => { navigate(-1) }}> <i class="fas fa-arrow-left"></i> {t("Back")}</button>
                    </div>

                    {data && data.data &&
                        <div className='viewuser_cont flt'>
                            <div className='row mb-4'>

                                <div className='col-sm-6'>
                                    <div className="basicuser_list flt" >
                                        <div className='row'>
                                            <div className='col-sm-6'>
                                                <div className='basicimg flt' >
                                                    {
                                                        data.data.profile_photo ?
                                                            <img src={image_url + data.data.profile_photo} />
                                                            : <img src={Default} />
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                <ul className='styldetails2 flt'>
                                                    <li><b>{t("Name")} :</b> <span>{data.data.name}</span></li>
                                                    <li><b>{t("Email")} :</b> <span>{data.data.email}</span></li>
                                                    <li><b>{t("User_Type")}:</b> <span>{data.data.user_type}</span></li>
                                                    <li><b>{t("Address")}:</b> <span>{data.data.address}</span></li>
                                                    <li><b> {t("Display_Name")}:</b> <span>{data.data.display_name}</span></li>
                                                    <li><b> {t("DOB")} :</b> <span>{moment(data?.data?.date_of_birth).format('DD/MM/yyyy')}</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='underline flt'>
                                            <div className=''>
                                                <ul className='styldetails2 flt'>
                                                    <li><b> {t("Favourite_Fashion")} : </b>{
                                                        data.data.fav_fashion.map((el, index) => {
                                                            return (<span key={el.id}>{(index ? ',' : '') + el.name}</span>
                                                            )
                                                        })}
                                                    </li>
                                                    <li><b> {t("Fav_Brand")}:</b> {data.data.fav_brand.map((el,index) => {
                                                        return (<span key={el.id} dangerouslySetInnerHTML={{__html:(index ? ',' : '') + el.name}}></span>
                                                        )
                                                    })}</li>
                                                    <li><b> {t("Suggestion")}:</b> <span>{data.data.kind_of_suggestions}</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }

    return (
        <>
            {Header()}
            <div className="page_container flt">
                {ViewUserContent()}
            </div>
            {FooterContent()}
        </>
    )
}

export default withTranslation()(ViewBasicUsers);