import React from "react"
import './footer.css'


   const FooterContent = () => {
    return (<div className="footer flt">
        <div className="foot_text flt">
            Designed By Chipsy IT Services PVT. LTD.
        </div>
    </div>)
}


export default FooterContent